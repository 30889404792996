.swiper-container {
    &.wp-slide-pagination-coverage-area {
        .swiper-pagination-bullets {
            text-align: left;

            .swiper-pagination-bullet {
                background-color: $light-500 !important;

                &.swiper-pagination-bullet-active {
                    background-color: $dark-600 !important;
                }
            }
        }
    }
}