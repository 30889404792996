// Start Base Heading Size
@mixin config_font_size ($size) {font-size: $size;}

@mixin sizeH1 {@include config_font_size(4.5rem)}
@mixin sizeH2 {@include config_font_size(3rem)}
@mixin sizeH3 {@include config_font_size(2.625rem)}
@mixin sizeH4 {@include config_font_size(2rem)}
@mixin sizeH5 {@include config_font_size(1.5rem)}
@mixin sizeH6 {@include config_font_size(1.125rem)}

h1 {@include sizeH1}
h2 {@include sizeH2}
h3 {@include sizeH3}
h4 {@include sizeH4}
h5 {@include sizeH5}
h6 {@include sizeH6}
// End Base Heading Size

// Use Wrap text size in H1 - h6
.wp-text-h1 * {@include config_font_size(4.5rem)}
.wp-text-h2 * {@include config_font_size(3rem)}
.wp-text-h3 * {@include config_font_size(2.625rem)}
.wp-text-h4 * {@include config_font_size(2rem)}
.wp-text-h5 * {@include config_font_size(1.5rem)}
.wp-text-h6 * {@include config_font_size(1.125rem)}
// Use Wrap text size in H1 - h6

// Start Font Size
.fs-12 {@include config_font_size(0.75rem)}
.fs-14 {@include config_font_size(0.875rem)}
.fs-16 {@include config_font_size(1rem)}
.fs-20 {@include config_font_size(1.25rem)}
.fs-24 {@include config_font_size(1.5rem)}
// End Font Size

.wp-bundle-text-default *{
  padding-bottom: 0;
  margin-bottom: 0;
  line-height: normal;
  font-weight: normal;
}

.wp-text-lh-normal *{line-height: normal;}

.wp-mb-0 * {margin-bottom: 0}


.tx-desc-general {color: $dark-500;}

.tx-desc-in-black {color: $dark-500;}


// Wrap Text color Span
.wp-text-gradient-brand {
  background: -moz-linear-gradient(90deg, #f9c900 0%, #ef541f 100%);
  background: -webkit-linear-gradient(90deg, #f9c900 0%, #ef541f 100%);
  background: -o-linear-gradient(90deg, #f9c900 0%, #ef541f 100%);
  background: -ms-linear-gradient(90deg, #f9c900 0%, #ef541f 100%);
  background: linear-gradient(90deg, #f9c900 0%, #ef541f 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.wp-text-gradient-brand-span {
  span {
    //background: linear-gradient(90deg, #f9c900 0%, #ef541f 100%);
    background: -moz-linear-gradient(90deg, #f9c900 0%, #ef541f 100%);
    background: -webkit-linear-gradient(90deg, #f9c900 0%, #ef541f 100%);
    background: -o-linear-gradient(90deg, #f9c900 0%, #ef541f 100%);
    background: -ms-linear-gradient(90deg, #f9c900 0%, #ef541f 100%);
    background: linear-gradient(90deg, #f9c900 0%, #ef541f 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.wp-text-white-span span {color: $white!important;}


// Use Wrap
.wp-text-light-100 * {color: $light-100;}
.wp-text-light-200 * {color: $light-200;}
.wp-text-light-300 * {color: $light-300;}

.wp-text-white * {color: $white}

.wp-text-tint-100 * {color: $tint-100;}
.wp-text-tint-200 * {color: $tint-200;}
.wp-text-tint-300 *, .wp-tint-300-span span {color: $tint-300!important;}


.wp-fw-500 * {font-weight: 500;}
.wp-fw-normal * {font-weight: normal;}


.size-404 {font-size: 7rem;}


.wp-blog-wordpress-content {
  p {
    font-weight: 500 !important;
    font-size: 100%;
    margin-bottom: 1rem !important;
    strong {font-weight: 500 !important;}
  }
  h2, h3, h4, h5, h6 {
    margin-top: 3rem !important;
    margin-bottom: 1.5rem !important;
    font-weight: 500 !important;
  }
  b, strong {font-weight: 500 !important;}
  ul, ol {
    font-weight: 300 !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    font-size: 100%;
    strong {font-weight: 500 !important;}
  }

  img {
    height: auto;
    border-radius: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 100%;
  }

  a {
    color: $primary-theme;
    font-weight: 500;
  }
}

.wp-blog-to-paragraph * {
  font-size: 1rem!important;
  margin-bottom: 0;
}


.lh-normal, .lh-normal * {line-height: normal;}
.lh-32, .lh-32 * {line-height: 32px;}
.lh-34, .lh-34 * {line-height: 34px;}
.lh-80, .lh-80 * {line-height: 80px;}


// typography responsive
// 1024pz
@include media-breakpoint-down(xl) {
  .wp-text-h2 {
    * {
      font-size: 2.5rem;
    }
  }
}

// 768px
@include media-breakpoint-down(lg) {
  .wp-text-h1,
  .wp-text-h3 {
    * {
      font-size: 2.5rem;
    }
  }
}

// 425px
@include media-breakpoint-down(md) {
  .wp-text-h1,
  .wp-text-h2,
  .wp-text-h3 {
    * {
      font-size: 1.75rem;
    }
  }

  .wp-text-h4 {
    * {
      font-size: $text-2xl;
    }
  }

  .wp-text-h5,
  .wp-text-h6 {
    * {
      font-size: $text-base;
    }
  }
}

@media screen and (max-width: 375px) {
}

@media screen and (max-width: 320px) {
}