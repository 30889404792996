@import 'variables';
@import '~bootstrap/scss/bootstrap';
@import "_configFont";
@import "unit-include";
@import "nav-mobile";
@import "custom-pagination";
@import "tab-story";

* {
  box-sizing: border-box;
}

html,body,* {
  padding: 0;
  margin: 0;
}
//body {background-color: #1a1a1a;}

@import "_locomotive";

.hv-100 {height: 100vh;}
.hv-min-100 {min-height: 100vh;}

.section-padding {
  padding-bottom: 8rem;
  padding-top: 8rem;
}

.section-padding-top {padding-top: 8rem;}
.section-padding-bottom {padding-bottom: 8rem;}
.pb-more-5rem {padding-bottom: 5rem;}
.my-section {
  padding: 10vw 0 10vw;
  position: relative;
}

// ==> Start Navbar
header {
  position: fixed;
  z-index: $z-9999;
  top: 0;
  right: 0;
  left: 0;

  .border-navbar {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }

  .border-navbar-black {
    border-bottom: 1px solid #dee2e6;
  }

  .navbar {
    z-index: $z-1099;

    .navbar-toggler {

      //width: clamp(3em, 4vw, 4em);
      //height: clamp(3em, 4vw, 4em);

      width: 2.5em;
      height: 2.5em;

      z-index: $z-1099;
      padding: 0;

      .line-wrapper {
        position: relative;
        height: 100%;
        width: 100%;

        .custom-toggler-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          transition: all 0.3s ease;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          display: block;
          width: 22px;
          height: 2px;
          border-radius: 8px;

          &.white {
            background-color: #fff !important;
          }

          &.black {
            background-color: #000 !important;
          }

          &:first-child {
            //top: 40%;
            top: 42%;
          }

          &:nth-child(2) {
            top: 50%;
          }

          &:last-child {
            //top: 60%;
            top: 55%;
          }
        }
      }

      &:focus {
        box-shadow: none;
      }

      &:not(.collapsed) {
        .line-wrapper {
          .custom-toggler-icon {
            top: 50%;
            left: 50%;

            &:first-child {
              transform: translate(-50%, -50%) rotate(45deg);
              -webkit-transform: translate(-50%, -50%)
              rotate(45deg);
              -moz-transform: translate(-50%, -50%) rotate(45deg);
            }

            &:nth-child(2) {
              opacity: 0;
              transform: translate(0) rotate(0deg);
              -webkit-transform: translate(0) rotate(0deg);
              -moz-transform: translate(0) rotate(0deg);
            }

            &:last-child {
              transform: translate(-50%, -50%) rotate(-45deg);
              -webkit-transform: translate(-50%, -50%)
              rotate(-45deg);
              -moz-transform: translate(-50%, -50%) rotate(-45deg);
            }
          }
        }
      }
    }

    .navbar-nav {
      .dropdown {
        .dropdown-toggle {
          transform: rotate(0deg) !important;
          transition: transform .3s ease !important;

          &:focus {
            transform: rotate(-180deg) !important;
          }
        }
        
        &:hover {
          .dropdown-menu {
            display: block;
          }
          
          .dropdown-toggle {
            transform: rotate(-180deg) !important;
          }
        }
      }

      .dropdown-menu {
        position: absolute;
        left: 0;
        top: 100%;
        padding: 0;
        border: 1px solid #e4e4e7;
        overflow: hidden;
        margin-top: 0;
        box-shadow: 4px 8px 24px rgba(0,0,0,.1);
        animation-name: dropdownAnimation;
        animation-duration: .3s;
        animation-fill-mode: both;
        
        a {
          padding: 0.5rem 1rem;
          font-size: $text-sm;
          font-weight: 400;
        }
      }

      .nav-item {
        padding: 0;

        .nav-link {
          position: relative;
          font-size: 0.875rem;
          font-weight: 400;
          padding: 0;
          height: 100%;
          display: flex;
          align-items: center;

          & + .dropdown-toggle {
            svg {
              &.arrow-dropdown-icon-desktop {
                color: $light-100;
              }
            }

            &::after {
              display: none;
            }
          }

          &.text-content-blur {
            & + .dropdown-toggle {
              svg {
                &.arrow-dropdown-icon-desktop {
                  color: $dark-100;
                }
              }
            }
          }
        }

        &:hover {
          .nav-link {
            &::after {
              content: '';
              display: block;
              height: 4px;
              width: 100%;
              background-color: #fff;
              margin-left: 0;
              border-radius: 4px;
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
              transition: background-color 0.3s linear;
            }

            &.text-content-blur {
              &::after {
                content: '';
                display: block;
                height: 4px;
                width: 100%;
                background-color: $black;
                margin-left: 0;
                border-radius: 4px;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                transition: background-color 0.3s linear;
              }
            }
          }
        }

        &.active {
          .nav-link {
            &::after {
              content: '';
              display: block;
              height: 4px;
              width: 100%;
              background-color: #fff;
              margin-left: 0;
              border-radius: 4px;
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
              transition: background-color 0.3s linear;
            }

            &.text-content-blur {
              &::after {
                content: '';
                display: block;
                height: 4px;
                width: 100%;
                background-color: $black;
                margin-left: 0;
                border-radius: 4px;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                transition: background-color 0.3s linear;
              }
            }
          }
        }
      }

      &.btn-login-container {
        margin-left: 2rem;

        .btn-login {
          font-size: 0.875rem;
          font-weight: 400;
          color: #fff;
          background-color: transparent;
          padding: 0.5rem 2rem;
          border: 1px solid #fff;
          border-radius: 0.25rem;
          transition: all 0.3s ease-in-out;

          &:hover {
            background-color: $primary-theme;
            color: $dark-400;
            border: 1px solid $primary-theme;
          }
        }

        .btn-login-secondary {
          font-size: 0.875rem;
          font-weight: 400;
          color: #000;
          background-color: transparent;
          padding: 0.5rem 2rem;
          border: 1px solid #000;
          border-radius: 0.25rem;
          transition: all 0.3s ease-in-out;

          &:hover {
            background-color: $primary-theme;
            color: $dark-400;
            border: 1px solid $primary-theme;
          }
        }
      }

      &.space-language {
        margin-left: 2rem;

        &::before {
          content: "";
          display: inline-block;
          margin-right: 2rem;
          width: 1px;
          height: 38px;
          background-color: $light-100;
          opacity: 0.2;
        }
      }
    }

    .navbar-collapse {
      .slc-container {
        .slc-prefix__control {
          width: 220px;
          height: 39px;
          //background-color: transparent;
          //border-width: 1px;
          border-width: 0;
          //border-color: rgba(255, 255, 255, 0.2);
          border-radius: 0.25rem;

          background: rgb(255 255 255 / 15%);
          //border-radius: 0.2rem;
          transition: background-color 300ms linear;

          svg {
            &.map-icon,
            &.arrow-icon {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              color: $light-100;
            }

            &.map-icon {
              left: 1rem;
            }

            &.arrow-icon {
              right: 1rem;
            }
          }
        }

        .slc-prefix__single-value,
        .slc-prefix__placeholder {
          font-size: $text-sm;
          font-weight: 400;
          color: $light-100 !important;
          padding: 0 1rem 0 2rem;
        }

        .slc-prefix__input-container {
          padding-right: 1rem;
          padding-left: 2rem;
        }
      }
    }
  }

  &.visible {
    top: 0;
    transform: translate(0, 0) !important;
  }

  &.blur-background {
    background-color: $light-100;
    box-shadow: 0 3px 10px rgb(0 0 0/5%);

    .navbar-nav {
      &.space-language {
        &::before {
          background-color: $dark-100;
          opacity: 0.2;
        }
      }
    }

    .navbar-collapse {
      .slc-container {
        .slc-prefix__control {
          border: none;
          background-color: $light-500;
        }

        .slc-prefix__single-value,
        .slc-prefix__placeholder,
        .slc-prefix__control svg.map-icon,
        .slc-prefix__input-container {
          color: $dark-100 !important;
        }
      }
    }
  }

  &.navbar-black-style {
    .navbar-collapse {
      .space-language {
        margin-left: 2rem;

        &::before {
          content: "";
          display: inline-block;
          margin-right: 2rem;
          width: 1px;
          height: 38px;
          background-color: $dark-100;
          opacity: 0.2;
        }
      }

      .slc-container {
        .slc-prefix__control {
          border: none;
          background: $light-500;
        }

        .slc-prefix__single-value,
        .slc-prefix__placeholder,
        .slc-prefix__control svg.map-icon,
        .slc-prefix__input-container {
          color: $dark-100 !important;
        }
      }
    }
  }
}

.navbar-brand {
  .gx-header-logo {
    height: 22px;
    //width: 100%;
  }

  .custom-gx-header-logo {
    height: 22px;
    width: auto;
    position: fixed;
    top: 2.25rem;
    left: 3rem;
    z-index: $z-999;
  }
}

.slc-container {
  .slc-prefix__control {
    width: 100%;
    height: 39px;
    background-color: transparent;
    border-width: 1px;
    border-color: rgba(255, 255, 255, 0.2);
    border-radius: 0.25rem;

    svg {
      &.map-icon,
      &.arrow-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: $light-100;
      }

      &.map-icon {
        left: 1rem;
      }

      &.arrow-icon {
        right: 1rem;
      }
    }
  }

  .slc-prefix__single-value,
  .slc-prefix__placeholder {
    font-size: $text-sm;
    font-weight: 400;
    color: $light-100 !important;
    padding: 0 1rem 0 2rem;
  }

  .slc-prefix__input-container {
    padding-right: 1rem;
    padding-left: 2rem;
  }

  //.slc-prefix__indicator {
  //  position: absolute;
  //  top: 50%;
  //  transform: translateY(-50%);
  //  left: 0.5rem;
  //  color: $light-100;
  //}

  .slc-prefix__value-container {
    padding: 4px 8px;
  }
}
// ==> End Navbar


// ==> Start head of page
.section-head-of-page {
  position: relative;

  .banner {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: center;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background: linear-gradient(
                      90deg,
                      #000000 47.4%,
                      rgba(0, 0, 0, 0) 96.1%
      );
      opacity: 0.3;
    }
  }

  .hero-video {
    .player-wrapper {
      width: 100%;
      height: 100vh;
      position: relative;
      overflow: hidden;
      
      .react-player {
        video {
          object-fit: cover;
          position: absolute;
          top: 0;
          left: 0;
          height: 100% !important;
          width: 100% !important;
        }
      }

      .overlay-video {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100% !important;
        width: 100% !important;
        // background: linear-gradient(90deg,#000 47.4%,transparent 96.1%);
        background: linear-gradient(90deg,#000 100%,transparent 96.1%);
        opacity: .3;
      }
    }
  }

  .slides {
    width: 100%;
    height: 100vh;

    .slide {
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);

      .slide-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        opacity: 0;
      }
    }
  }

  .slideshow-control {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    border: none;
    z-index: 1;

    button {
      border: none;
      width: 56px;
      height: 3px;
      opacity: 0.5;
      transition: all 0.7s ease-in-out, color 1s;
      
      &.active {
        opacity: 1;
        color: $light-100;
      }
      
      &:nth-child(1) {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      &:nth-last-child(1) {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }

  .img-linear-background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    //height: 100vh;
    background: linear-gradient(90deg, $dark-100 47.4%, rgba(0, 0, 0, 0) 96.1%);
    opacity: 0.3;
  }

  .hero-text-wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    max-width: 1000px;

    .hero-text {
      font-size: 5rem;
      line-height: 90px;
      font-weight: 600;
      color: #fff;
      width: auto;
      margin-bottom: 20px;
      transform: none !important;
    }

    .hero-text-transform {
      font-size: 5rem;
      line-height: 90px;
      font-weight: 600;
      color: #fff;
      width: auto;
      margin-bottom: 20px;
    }

    .hero-desc {
      > * {
        //font-size: 2rem;
        font-size: 1.5rem;
        line-height: 36px;
        font-weight: 400;
        color: #fff;
        max-width: 550px;
        margin-bottom: 0;
      }
    }
  }
}

.wp-hero-toggle-audio {
  position: absolute;
  bottom: 3rem;
  left: 3rem;
  height: 32px; 
  width: 32px;
}
// ==> End head of page


// ==> Start Animate Splash Screen
.page-effect-close {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 100%;
  height: 100vh;
  width: 100vw;
  z-index: 199999;
}

.page-effect-in {
  position: fixed;
  top: 0;
  bottom: 0;
  //right: 100%;
  //right: 100vw;
  left: 0;
  height: 100vh;
  width: 100vw;


  //z-index: 99999;
  //background-color: $primary-theme;
  //left: 0;
  //transition: opacity 0.5s ease;
  //transform-origin: top;
}

.page-effect-close-y {
  position: fixed;
  top: 0;
  bottom: 100%;
  //right: 100%;
  height: 100vh;
  width: 100vw;
  z-index: 199999;
}

.ef-z-index-1 {z-index: 99999;}
.ef-z-index-2 {z-index: 9999;}
.ef-z-index-3 {z-index: 999;}

.bg-ef-gradient {
  //background: linear-gradient(206.57deg, #090700 0%, #474747 83.33%);
  background: linear-gradient(180deg, #000000 30.85%, #313131 102.12%);
}

.opening-splash-screen  {
  //background-color: #fff;
  z-index: 99999;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  transition: opacity 0.5s ease;
  transform-origin: top;
}

body.ready {
  .opening-splash-screen {
    opacity: 0;
    pointer-events: none;
  }
}
//==> End Animate Splash Screen

// ==> Start Marquee - Home Page
.marquee-text-unrivaled {
  padding: 194px 0 141px 0;
  background-color: $primary-theme;
  position: relative;
  overflow: hidden;

  .bg-icon-pills {
    position: absolute;
    top: 208px;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: $z-n-1;
  }

  .marquee-container {
    overflow-x: visible !important;

    .marquee {
      .text-unrivaled-slider {
        margin-left: 250px;
        .text-info {
          font-size: 18.75rem; // 300px
          line-height: 360px;
          font-weight: 600;
          color: #fff;
        }
      }
    }
  }

  .text-description-wrapper {
    > * {
      font-weight: 500;
      margin-bottom: 0;
    }
  }
}
// ==> End Marquee - Home Page

// ==> Start Footer
footer {
  padding-bottom: 2.5rem;
  min-height: 100vh;
  background-color: $dark-200;

  &.wp-footer-ads {
    padding-bottom: 0;
    min-height: auto;
    background-color: white;
  }

  .sitemap {
    padding: 209px 0 85px 0;
    position: relative;
    overflow: hidden;

    .bg-rectangle-sitemap {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 674px;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }

    h2 {
      font-size: 70px;
      line-height: 84px;
      font-weight: 600;
      background: linear-gradient(90deg, #f9c900 0%, #ef541f 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .information-link {
      margin-top: 120px;
      margin-bottom: 80px;
    }

    .header-category {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 30px;
      color: #fff;
      opacity: 0.4;
    }

    .item-footer {
      ul {
        list-style: none;
        padding: 0;
        margin-bottom: 0;

        li {
          margin-bottom: 20px;
          font-size: 14px;
          font-weight: 500;

          a,
          & p {
            color: #fff;
          }
        }
      }
    }
  }

  .footer-section {
    img {
      width: 170px;
    }

    p {
      font-size: 14px;
      color: #fff;
      //opacity: 0.4;
    }
  }
}
// ==> End Footer

//#header-text * {display: inline !important;}
.appear{
  opacity:1;
}


// ==> Start Swiper For Package
.swiper-pagination-bullets {
  bottom: 8%;

  .swiper-pagination-bullet {
    height: 3px;
    width: 56px;
    background-color: $light-300;
    border-radius: 0;
    opacity: 1;
    margin: 0!important;
    padding: 0!important;
    transition: all 0.7s ease-in-out, color 1s;
    -webkit-transition: all 0.7s ease-in-out, color 1s;

    &.swiper-pagination-bullet-active {
      width: 56px;
      border-radius: 1rem !important;
      background-color: $black
    }

    &:first-child {
      border-radius: 1rem 0 0 1rem;
    }

    &:last-child {
      border-radius: 0 1rem 1rem 0;
    }
  }
}

.my-swiper-package {
  position: unset !important;

  @extend .swiper-pagination-bullets;

  .swiper-slide {
    border-radius: 10px;
    border: 0;
    background-color: $light-300;
    padding: 2rem;
    min-height: 18.75rem;
    &.min-height-350 {min-height: 21.875rem;}
    &.min-height-375 {min-height: 23.438rem;}

    &.card-package {
      transition: all 0.7s ease-in-out, color 1s, opacity 1000ms;
      -webkit-transition: all 0.7s ease-in-out, color 1s, opacity 1000ms;

      .product-title {
        font-size: 2.5rem; // 40px
        font-weight: 500;

        sup {
          font-size: $text-xl;
          margin-left: 8px;
        }
      }

      .dedicated-product-title {
        font-size: 2.5rem; // 40px
      }

      .with-color-primary {
        color: $tint-300;
        transition: color 1s;
        -webkit-transition: color 1s;
      }

      .priority-services {
        margin-top: 72px;
      }
      
      .product-speed,
      .dedicated-product-speed {
        font-size: 5rem; // 80px
        font-weight: 600;
        color: $dark-200;
      }

      .product-unit,
      .dedicated-product-unit {
        font-size: 2rem;
        font-weight: 600;
        line-height: normal;
      }

      .product-sub-unit {
        font-weight: 500;
        color: $tint-100;
      }

      .product-title,
      .product-speed,
      .product-unit,
      .product-sub-unit,
      .product-tc {
        transition: color 1s;
        -webkit-transition: color 1s;
      }

       svg {
        height: 46px;
        width: 46px;
      }
      .icon-arrow {
        opacity: 0;
        transition: all 0.7s ease-in-out, color 1s, opacity 1000ms;
        -webkit-transition: all 0.7s ease-in-out, color 1s, opacity 1000ms;
      }


      .card-footer {
        .sub-title {
          font-weight: 500;
          color: $light-100;
        }

        .title {
          font-weight: 600;
          color: $light-100;

          span {
            font-weight: 400;
            color: inherit;
          }
        }

        .btn-subscribe {
          padding: 0.75rem 1rem;
          visibility: hidden;
          opacity: 0;
          transition: visibility 1s, opacity 0.5s ease-in-out;

          span {
            font-size: $text-base;
          }

          svg {
            height: 16px;
            width: 16px;
          }
        }
      }

      &:hover {
        background-color: $tint-300 !important;

        .icon-arrow {
          //display: block;
          opacity: 1;
        }

        .product-title {color: $dark-300 !important;}

        sup {
          color: $dark-300 !important;
          transition: color 1s;
          -webkit-transition: color 1s;
        }

        .product-speed,
        .product-sub-unit {
          color: $dark-200 !important;
        }

        .product-unit,
        .product-tc {
          color: #212529 !important;
        }

        .with-color-primary {
          color: $tint-100;
        }

        .btn-subscribe {
          visibility: visible;
          opacity: 1;
        }
      }

      &.card-with-img {
        &:hover {
          .dedicated-product-unit,
          sup.dedicated-product-number {
            color: $light-100 !important;
          }
        }
      }


      &.with-primary-brand {
        .pkg-logo-active {
          display: none;
          width: 160px;
        }
        .pkg-logo-no-active {
          display: block;
          width: 160px;
        }

        &:hover, &.swiper-slide-active {
          .pkg-logo-active {
            display: block;
          }
          .pkg-logo-no-active {
            display: none;
          }
        }

        &.swiper-slide-active {

          background-color: $primary-theme!important;

          .with-color-primary {color: $tint-100;}

          .icon-arrow {
            //display: block;
            opacity: 1;
          }
        }
      }

      &.with-dark-brand {
        .btn-subscribe {
          background-color: $dark-300;
        }

        .pkg-logo-active {
          display: none;
        }
        .pkg-logo-no-active {
          display: block;
        }

        &.swiper-slide-active, &:hover {
          background-color: $dark-300 !important;
          .pkg-logo-active {
            display: block;
          }
          .pkg-logo-no-active {
            display: none;
          }

          .btn-subscribe {
            background-color: $primary-theme;
            color: $dark-300;
          }
          .product-title,
          .product-speed,
          .product-unit,
          .product-sub-unit,
          .product-tc,
          .product-price {
            color: $light-100!important;
            transition: color 1s;
            -webkit-transition: color 1s;
          }
          .product-title sup, .icon-svg {
            color: $light-100!important;
          }
        }
      }
    }

    &.swiper-slide-dark:not(.swiper-slide-active) {
      background-color: $dark-300;

      .product-title,
      .product-speed,
      .product-unit,
      .product-sub-unit,
      .product-tc,
      .product-price {
        color: $light-100;
        transition: color 1s;
        -webkit-transition: color 1s;
      }

      .product-sub-unit {font-weight: 400;}

      &:hover {
        .product-price {
          color: $dark-100 !important;
        }
      }
    }

    &.swiper-slide-active,
    &.swiper-slide-duplicate-active {
      .slide-image {
        transform: scale3d(1, 1, 1);
      }
    }

    &.swiper-slide-active .card-footer {
      .btn-subscribe {
        visibility: visible;
        opacity: 1;
      }
    }


    &.with-bg-dark:not(.swiper-slide-active) {
      background-color: $dark-300;

      .product-title, 
      .product-speed, 
      .product-unit, 
      .product-link-icon {
        color: $white;
      }

      .product-sub-title, 
      .product-sub-unit {
        color: #ffffff80;
        transition: color 1s;
        -webkit-transition: color 1s;
      }

      svg {
        color: $light-100 !important;
        transition: color 1s;
        -webkit-transition: color 1s;
      }

      .product-sub-title {

      }
      .product-speed {

      }
      .product-unit {

      }
      .product-sub-unit {

      }
      .product-link-icon {}
    }

    &.with-bg-dark,
    &.with-bg-dark.swiper-slide-active {
      //background-color: $dark-300;
      //.product-title,
      //.product-speed,
      //.product-unit,
      //.product-sub-unit,
      //.product-tc,
      //.product-price {
      //  color: $light-100;
      //  transition: color 1s;
      //  -webkit-transition: color 1s;
      //}

      .product-sub-unit {font-weight: 400;}

      &:hover {
        svg {
          color: $dark-100 !important;
        }

        .product-sub-title, 
        .product-sub-unit {
          color: $tint-100 !important;
          opacity: 1 !important;
        }
      }
    }
  }

  &.packet-dedicated-list {
    .swiper-pagination-bullets {bottom: 4%;}

    .swiper-slide {
      height: 100%;
      min-height: 350px;
      &.card-package {
        transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;

        img {
          &.img-packet-dedicated {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        .img-linear-background {
          position: absolute;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(180deg, #181819 0%, rgba(24, 24, 25, 0.939342) 20.72%, rgba(24, 24, 25, 0) 84.37%);
          transform: matrix(1, 0, 0, -1, 0, 0);
        }

        &:hover {
          background-color: $tint-600 !important;
        }

        &.card-package-img {
          &:hover {
            background-color: transparent !important;
          }
        }
      }
    }
  }
}

.swiper-pagination-white {
  .swiper-pagination-bullets {
    .swiper-pagination-bullet {
      background-color: $light-300;
      opacity: .3;
      &.swiper-pagination-bullet-active {
        background-color: $light-100;
        opacity: 1;
      }
    }
  }
}

.wp-product-footer {
  margin-top: 6rem;
}

.wp-swiper-package {
  padding-top: 4rem;
  padding-bottom: 6rem;
  position: relative;
}
// ==> End Swiper For Package


// ==> Start Swiper For Image Article
.my-swiper-article {
  position: unset !important;

  @extend .swiper-pagination-bullets;

  .swiper-pagination {
    &.swiper-pagination-bullets {
      bottom: 10px;
    }
  }

  .swiper-slide {
    &.card {
      // .card-body {
      //   border-radius: $text-lg;
      //   height: 100%;
      //   min-height: 360px;
      //   max-height: 360px;

      //   .swiper-img-wrapper {
      //     img {
      //       width: 100%;
      //       height: 360px;
      //       object-fit: cover;
      //       object-position: center;
      //       // display: block;
      //     }
      //   }
      // }

      .card-footer {
        h3 {
          font-size: 1.75rem;
          font-weight: 500;
          color: $light-100;
        }

        hr {
          border: 1px solid $light-100;
          opacity: 0.1;
        }

        p {
          font-size: $text-base;
          font-weight: 400;
          color: $light-100;
          opacity: 0.5;
        }
      }
    }
  }
}
// ==> End Swiper For Image Article


// Start Part Home Page
.sc-home-tab-story {
  // position: relative;

  .nav-tab-banner {
    width: 100%;
    min-height: 100vh;
    max-height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .nav-tab-wrapper {
    .nav-tabs {
      .nav-item {
        .nav-link {
          padding-right: 0;
          padding-left: 0;
          font-size: 21px;
          font-weight: 500;

          &.active {
            color: $primary-theme !important;

            & {
              border-bottom: 2px solid $primary-theme !important;
            }
          }

          &:hover {
            color: $primary-theme !important;
            border-bottom: 2px solid $primary-theme !important;
          }
        }
      }

      &.nav {
        column-gap: 70px;
        row-gap: 20px;
      }
    }

    .tab-content {
      .tab-pane {
        .wp-tap-pane {
          * {
            font-weight: 500;
            max-width: 591px;
          }
        }
      }
    }
  }
}

.sc-home-info-package {
  position: relative;

  .bg-image-abstract {
    position: absolute;
    //top: 31px;
    top: -75px;
    z-index: $z-n-999;
    width: 100%;
    height: 869px;
    object-fit: contain;
    object-position: center;
  }

  .img-linear-background {
    position: absolute;
    top: 463px;
    z-index: $z-n-998;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) -60.18%, #FFFFFF 84.32%);
    width: 100%;
    height: 437px;
  }

  .wrap-package-info {
    //padding: 275px 0 119px 0;
    margin-bottom: 70px;
    overflow: hidden;
    position: relative;
  }

  .space-description-to-btn {
    margin-top: 8rem;
  }

  .wp-title-description {
    * {
      margin-bottom: 0;
      //font-size: 4.5rem;
    }
  }
}

.gx-app-promo {
  .card {
    height: 100%;

    &.card-mobile-app {
      padding: 4.75rem 2.625rem 3.375rem 2.625rem;
      background-color: #252525;
      border-radius: 10px;
      position: relative;
      overflow: hidden;

      h3 {
        font-size: 40px;
        font-weight: 500;
      }

      p {
        font-weight: 500;
        margin-bottom: 0;
        max-width: 315px;
      }

      .img-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin: 0;

        .mobile-app-bg {
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }

      .img-linear-background {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 40%;
        background: linear-gradient(
                        180deg,
                        rgba(31, 31, 34, 0) 0%,
                        #1f1f22 97.54%
        );
      }

      .content-wrapper {
        z-index: $z-2;

        .my-gx-app-text {
          > * {
            font-size: 2.5rem; // 40px
            margin-bottom: 0;
          }
        }
      }

      .mobile-app-wrapper {
        gap: 17px;
        z-index: $z-2;

        img {
          width: 230px;
          object-fit: cover;
          object-position: center;
        }
      }
    }

    &.card-promo {
      padding: 2.875rem 1.5rem;
      background-color: #252525;
      border-radius: 10px;
      overflow: hidden;

      h3 {
        font-size: 50px;
        font-weight: 500;
      }

      .text-promo * {
        margin-top: 65px;
        font-weight: 600;
        font-size: 2.625rem;
        margin-bottom: 0;
      }
      //}

      p {
        font-weight: 500;
        margin-top: 107px;
        margin-bottom: 0;
        max-width: 248px;
      }

      .img-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: 0;

        .mobile-app-bg {
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }

      .content-wrapper {
        z-index: $z-2;

        .gx-promo-text {
          > * {
            font-size: 3rem; // 40px
            margin-bottom: 0;
          }
        }

        .mobile-app-wrapper {
          gap: 17px;

          img {
            width: 230px;
            object-fit: cover;
            object-position: center;
          }
        }
      }
    }
  }
}

.my-partner {
  padding: 0 0 234px 0;
  overflow: hidden;

  .partner-description-wrapper {
    > * {
      font-size: 2.5rem;
      font-weight: normal;
      color: $primary-theme;
      margin-bottom: 45px;
      max-width: 526px;
    }
  }

  p {
    &.partner-title-wrapper {
      font-weight: 400;
      font-size: $text-base;
      margin-bottom: 80px;
    }
  }

  .partner-slideshow {
    .marquee {
      .initial-child-container {
        min-width: 100%;
        align-items: center;
      }

      .child {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .card-partner {
        background-color: $dark-200;
        border-radius: 14px;
        border: 0;
        align-items: center;
        justify-content: center;
        padding: 2rem 4.5rem;
        margin: 0 10px;
        height: 100%;
        width: 250px;
        transition: all 0.3s ease-in-out;

        .img-partner {
          height: auto;
          width: 100%;
          min-height: 92px;
          max-height: 92px;
          // max-width: 120px;
          object-fit: contain;
          object-position: center;
        }

        &:hover {
          cursor: pointer;
          box-shadow: inset 0 0 0 2px $primary-theme;
        }
      }
    }
  }
}

.faq-text-animation {
  padding: 0 0 119px 0;
  overflow-x: hidden;

  .border-faq-animation {
    border-bottom: 1px solid $light-300;

    p {
      &.faq-title {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: $light-100;
        opacity: 0.5;
      }
    }
  }

  .faq-text-slider {
    display: flex;
    align-items: center;

    .marquee {
      img {
        height: 134px;
        width: 213px;
        object-fit: cover;
        object-position: center;
        margin-right: 28px;
        margin-left: 48px;
      }

      p {
        font-size: 60px;
        font-weight: 500;
        line-height: 60px;
        letter-spacing: 0.04em;
        background: linear-gradient(
                        90deg,
                        #f9c900 0%,
                        #f3731d 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        opacity: 0.8;
      }
    }
  }
}

.sc-faq-only {
  //padding: 164px 0;
  //margin-top: -2rem;
  position: relative;
  border-radius: 30px 30px 0 0;
  background-color: #fff;

  p {
    //@include fontNimbus;
    //font-size: 1.5rem;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 20px;
  }

  .normal-size {
    > * {
      font-size: $text-base;
      margin-bottom: 0;
    }
  }

  h2 {
   font-size: 48px;
   line-height: 68px;
   font-weight: 600;
   margin-bottom: 60px;
  }

  .card {
    color: #000;

    &.card-faq {
      border-radius: 0.5rem;
      margin-bottom: 1.5rem;
      overflow: auto;

      .card-header {
        padding: 24px 40px;
        background-color: $light-200;

        h5 {
          font-size: $text-xl;
          font-weight: 500;
          color: #000;
        }

        &:hover {
          cursor: pointer;
        }
      }

      .card-body {
        padding: 1.5rem 2.5rem 1.5rem 2.5rem;
        background-color: $light-200;
      }
    }
  }
}

.sc-home-wp-blog {
  display: flex;
  align-items: center;

  img {
    border-radius: 100%;
    width: 215px;
    height: 134px;
  }
}
// End Part Home Page


// Start Page Coverage Area
.section-head-coverage-area {
  .illustration-globe {
    position: absolute;
    top: 231px;
    right: 0;
    width: auto;
    height: 869px;
    object-fit: contain;
    object-position: center;
    z-index: $z-n-2;
  }

  .illustration-globe-left {
    position: absolute;
    top: 522px;
    left: 0;
    width: auto;
    height: 736px;
    object-fit: contain;
    object-position: center;
    z-index: $z-n-2;
  }

  .img-linear-background {
    position: absolute;
    left: 0;
    top: 554px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 3.08%, rgba(0, 0, 0, 0.690863) 46.68%, #000 84.32%);
    width: 100%;
    height: 547px;
    z-index: $z-n-1;
  }

  .img-linear-background-left {
    position: absolute;
    right: 0;
    top: 797px;
    background: linear-gradient(180deg, rgba(255, 250, 229, 0) 0%, rgba(255, 250, 229, 0.757127) 28.42%, #FFFAE5 60.77%);
    width: 100%;
    height: 519px;
    z-index: $z-n-1;
  }

  .slc-container {
    .check-coverage-location {
      &.slc-prefix__control {
        border: 1px solid $tint-800;

        svg {
          &.map-icon {
            color: $tint-800;
            height: 20px;
            width: 20px;
          }

          &.arrow-icon {
            color: $primary-theme;
            height: 20px;
            width: 20px;
          }
        }
      }

      &.slc-prefix__single-value {
        color: $dark-100 !important;
        font-weight: 500 !important;
      }
    }
  }
}

.packet-coverage-area {
  .card {
    &.custom-card-packet {
      padding: 3rem;
      border: 1px solid rgba(0, 0, 0, 0.05);
    }
  }
}

.form-check-custom {
	position: relative;

  input {
    position: absolute;
    top: 50%;
    z-index: $z-1;
    transform: translateY(-50%);
    margin-left: 1rem !important;
    margin-top: 0;
  }
}

.form-check-input[type=radio] {
  background-color: $tint-700;
  border: 1px solid $tint-800;
}

.form-check-input:checked {
  background-color: $primary-theme;
  border-color: $primary-theme;
}

.form-check-input:focus {
  box-shadow: none;
}

.form-check-custom input[type="radio"] ~ label {
	position: relative;
	cursor: pointer;
	width: 100%;
  color: $light-100;
	border: 1px solid $tint-800;
	border-radius: 4px;
}

.form-check-custom input[type="radio"]:checked ~ label {
  background-color: rgba(249, 201, 0, .1);
  color: $dark-100 !important;
  border: 1px solid #F9C90080
}

.form-check-custom input[type="radio"]:empty ~ label span {
	display: inline-block;
}

.form-check-custom input[type="radio"]:empty ~ label span.form-check-custom-title {
	font-size: $text-base;
	font-weight: 500;
	margin: 12px 5px 12px 50px;
}

.form-check-coverage-new {
  .custom-maps {
    top: -16%;
    right: 0;
    left: 0;
  }

  input {
    &.form-control {
      height: 46px;
      border-radius: 6px;
    }
  }
}

.wp-map-config {
  > div:first-child {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.box-search-selected {
  border: 1px solid #d9d9d9;
  box-shadow: 1px 2px 2px 0px #00000014;
  padding: 0.8rem 0;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
}

.form-check-is-company input[type="radio"]:checked ~ label span {
  color: $primary-theme !important;
  opacity: 1 !important;
  margin-bottom: 10px;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: -10px;
    background-color: $primary-theme;
  }
}

.custom-zoom-button-plus,
.custom-zoom-button-minus {
  background-color: $light-100;
  // border: 1px solid rgba(0, 0, 0, 0.05);
  border: 0;
  box-shadow: 0px 5.39487px 21.5795px rgba(96, 100, 112, 0.1);
  color: $primary-theme;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  right: 0.5rem !important;
}

.custom-zoom-button-plus {
  font-size: 1.80rem;
  bottom: 40px !important;
}

.custom-zoom-button-minus {
  font-size: 3.5rem;
  line-height: 3rem;
  bottom: 100px !important;
}
// End Page Coverage Area

// Start Page Product
.page-product-wp-section-xlite {
  .illustration-globe {
    position: absolute;
    top: 231px;
    right: 0;
    width: auto;
    height: 869px;
    object-fit: contain;
    object-position: center;
    z-index: $z-n-2;
    opacity: 25%;
  }

  .img-linear-background {
    position: absolute;
    right: 0;
    top: 550px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) -60.18%, #FFFFFF 53.32%);
    //background: linear-gradient(180deg, rgba(255, 255, 255, 0) -60.18%, #FFFFFF 84.32%);
    //background: linear-gradient(180deg, rgba(0, 0, 0, 0) 3.08%, rgba(0, 0, 0, 0.690863) 46.68%, $dark-100 84.32%);
    width: 100%;
    height: 565px;
    z-index: $z-n-1;
  }
}

.product-xlite {
  .main-description-wrapper {
    * {
      max-width: 1152px;
      font-weight: 500 !important;
    }
  }

  .point-description-wrapper {
    * {
      margin-bottom: 0;
    }
  }
}

.product-signature {
  .main-description-wrapper,
  .point-description-wrapper {
    * {
      margin-bottom: 0;
    }
  }
}

.product-dedicated {
  .main-description-wrapper {
    * {
      font-weight: 500;
    }
  }
}

.customized-service {
  .main-description-wrapper {
    * {
      font-weight: 500;
      max-width: 779px;
    }
  }

  .data-service-description-wrapper {
    * {
      margin-bottom: 0;
    }
  }
}

.wp-page-product-banner-service {
  min-height: 520px;
  overflow: hidden;
  display: flex;
  width: 100%;
  .banner {
    width: 100%;
    object-fit: cover;
    object-position: center;
    height: 100%;
  }
}
// End Page Product


// Start Page Signature
.packet-signature {
  .illustration-globe {
    position: absolute;
    top: 231px;
    right: 0;
    width: auto;
    height: 869px;
    object-fit: contain;
    object-position: center;
    z-index: $z-n-2;
  }

  .img-linear-background {
    position: absolute;
    right: 0;
    top: 554px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 3.08%, rgba(0, 0, 0, 0.690863) 46.68%, $dark-100 84.32%);
    width: 100%;
    height: 547px;
    z-index: $z-n-1;
  }

  .main-description-wrapper {
    > * {
      margin-bottom: 0;
    }
  }

  .main-sub-description-wrapper {
    > * {
      max-width: 442px;
    }
  }

  .advantages-list {
    //margin: 90px 0 114px 0;

    .advantages-list-border {
      display: flex;
      gap: 12px;
      align-items: center;
      padding: 24px 0;
      border-bottom: 1px solid $dark-400;

      img {
        height: 18px;
        width: 18px;
      }

      &.left-advantage,
      &.right-advantage {
        &:last-child {
          border-bottom: none;
        }
      }
    }

    .col-6:nth-child(1) .advantages-list-border, .col-6:nth-child(2) .advantages-list-border {
      border-top: 0 solid $dark-400;
    }

  }
  .point-description {
    * {
      margin-bottom: 0;
    }
  }
}

.product-signature-tagline {
  .bg-icon-pills-dark {
    position: absolute;
    //top: -445px;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    //height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: $z-n-2;
  }

  .img-linear-background {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 1058px;
    background: linear-gradient(180deg, #171718 15.93%, rgba(23, 23, 24, 0.731408) 64.96%, rgba(23, 23, 24, 0.5) 100%);
    transform: matrix(1, 0, 0, -1, 0, 0);
    z-index: $z-n-1;
  }

  .description-wrapper {
    * {
      margin-bottom: 0;
      max-width: 1152px;
    }
  }
}

.banner-addon-signature {
  //.main-description-wrapper {
  //  //margin-bottom: 36px;
  //
  //  * {
  //    margin-bottom: 0;
  //  }
  //}

  .sub-description-wrapper {
    * {
      margin-bottom: 0;
    }
  }

  img {
    &.promotion-image-bg {
      width: 100%;
      //height: 592px;
      object-fit: cover;
      object-position: center;
    }
  }

  //.promotion-desc-wrapper {
  //  img {
  //    height: 32px;
  //    width: 32px;
  //    object-fit: cover;
  //    object-position: center;
  //  }
  //}
}

.coverage-area-section {
  .description-wrapper,
  .sub-description-wrapper {
    * {
      margin-bottom: 0;
    }
  }

  .coverage-area-bg {
    //svg {
    //  height: 76px;
    //  width: 76px
    //}
  }
}

.signature-package-card {
  position: relative;
  overflow: hidden;
  background-color: $dark-200;
  border-radius: 0.5rem;
  padding: 1.5rem;
  color: $white;

  .space {
    width: 100%;
    height: 100%;
    margin-top: 3rem;
    z-index: 1;
  }

  .product-speed {
    font-size: 5rem;
    font-weight: 600;
  }

  .product-unit {
    font-size: 2rem;
    font-weight: 500;
    line-height: normal;
  }

  .product-sub-unit {
    font-weight: 400;
  }

  .bg-img {
    position: absolute;
    right: -20%;
    top: 0;
    width: 100%;
    opacity: .75;
  }
}

.signature-package-radio-btn {
  padding: .5rem;
  border-radius: 0.5rem;
  border: 1px solid $tint-300;
  background-color: rgb(255 249 226 / 11%);
  cursor: pointer;

  .form-check-input:checked {
    background-color: unset;
    border-color: $tint-300;
  }
}
// End Page Signature


// Start Page XLite
.xLite-addon-banner {
  height: 980px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.xlite-tagline {
  .content-description-wrapper {
    * {
      margin-bottom: 0;
      max-width: 1152px;
    }
  }
}

.packet-xlite {
  .main-description-wrapper,
  .point-description-wrapper {
    * {
      margin-bottom: 0;
    }
  }
}

.xlite-info-addon {
  .main-description-wrapper,
  .main-sub-description-wrapper {
    * {
      margin-bottom: 0;
    }
  }
}
// End Page XLite


// Start Page Dedicated
.wp-page-dedicated {
  overflow: hidden;

  .illustration-globe {
    position: absolute;
    top: -101px;
    width: 100%;
    //height: 100%;
    //height: 1715px;
    height: auto;
    object-fit: cover;
    object-position: center;
    z-index: $z-n-4;
  }

  .img-linear-background {
    position: absolute;
    top: 756px;
    left: 0;
    right: 0;
    width: 100%;
    //height: 862px;
    height: 100%;
    //background: linear-gradient(180deg, #181819 0%, rgba(24, 24, 25, 0) 100%);
    background: linear-gradient(0deg, #181819 40%, rgba(255,255,255,0) 100%);
    transform: matrix(1, 0, 0, -1, 0, 0);
    z-index: $z-n-2;
  }

  .description-wrapper {
    * {
      margin-bottom: 0;
      max-width: 906px;
    }
  }

  .description-two-wrapper,
  .sub-description-two-wrapper {
    * {
      margin-bottom: 0;
    }
  }

  .description-wrapper {
    * {
      margin-bottom: 0;
      max-width: 906px;
    }
  }

  .description-two-wrapper,
  .sub-description-two-wrapper {
    * {
      margin-bottom: 0;
    }
  }
}

.dedicated-intro-banner {
  height: 100%;
  width: 100%;
  max-height: 850px;
  object-fit: cover;
  object-position: center;
}

.dedicated-access {
  .content-description-wrapper,
  .content-sub-description-wrapper {
    * {
      margin-bottom: 0;
    }
  }
}

.dedicated-article {
  .content-description-wrapper {
    * {
      margin-bottom: 0;
      max-width: 1152px;
    }
  }

  .article-description-wrapper,
  .article-two-description-wrapper {
    * {
      margin-bottom: 0;
    }
  }

  .wp-dedicated-article {
    .card {
      border: none;
      height: 442px;
      border-radius: 1.25rem;
      background-color: transparent;

      &.card-featured {
        overflow: hidden;

        .img-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: $z-1;
          margin: 0;
          .img-cover {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
        
        .img-background-side-linear {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: $z-2;
          background: linear-gradient(270deg, #0F0F10 17.46%, rgba(15, 15, 16, 0.570098) 43.19%, rgba(15, 15, 16, 0) 64.66%);
          transform: rotate(180deg);
        }
        
        .img-background-bottom-linear {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: $z-2;
          background: linear-gradient(180deg, #0F0F10 22.53%, rgba(15, 15, 16, 0.405336) 63.18%, rgba(15, 15, 16, 0) 100%);
          transform: matrix(1, 0, 0, -1, 0, 0);
        }
        
        .meta-wrapper {
          //padding: 60px;
          padding: 3rem;
          position: absolute;
          top: 0;
          bottom: 0;
          z-index: $z-4;
          color: $light-100;
          width: 100%;
          display: flex;
          justify-content: flex-end;
          flex-direction: column;
          //a {
          //  &:hover {
          //    text-decoration: none;
          //  }
          //}

          .product-list-title {
            //margin-bottom: 2rem;

            //span {
            //  font-size: $text-sm;
            //  font-weight: 400;
            //  display: block;
            //  color: $light-100;
            //  opacity: 0.5;
            //}
            //
            //h3 {
            //  font-size: 2rem;
            //  font-weight: 500;
            //  color: $light-100
            //}
            //p {
            //  font-size: $text-base;
            //  font-weight: 400;
            //  color: $light-100;
            //  opacity: 0.5;
            //  max-width: 272px;
            //}
          }
        }
      }
    }
  }
}
// End Page Dedicated


// Start Page Blog
.blog-card-banner {
  position: relative;
  max-height: 85vh;
  width: 100%;
  overflow: hidden;
  @include b-radius-16-px();

  img {object-fit: cover; height: 100%; position: relative!important;}
}

.blog-card-content {
  .cover-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: 300px;
    @include b-radius-8-px();
  }

  .blog-content {

  }
}

.bg-dots-dark {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  object-fit: cover;
  object-position: center;
  z-index: -2;
}

.blog-detail-header {
  height: 125vh;
  //background: #F7F7F7;
}
.blog-detail-overflow {
  //margin-top: -20rem;
  margin-top: -25rem;
  position: relative;
}

.blog-cover-more {
  height: 275px;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: .5rem;
  img {
    object-fit: cover;
  }
}

.line-limit-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
// End Page Blog


// Start Page Customize Service
.wp-slide-customize-service {
  //.swiper-wrapper {height: 400px!important;}
  .swiper-wrapper {
    //height: auto!important;
    margin-bottom: 4rem;

    .swiper-slide {
      position: relative;
      height: 100%;
      display: flex;
    }
  }
  .swiper-pagination {text-align: left;}
}
.slide-card-customize-service {
  background-color: $dark-300;
  color: $white;
  border-radius: 1rem;
  padding: 7rem 3rem 3rem;
}


.customize-service-intro-overlay {
  margin-top: -7rem;
  //position: absolute;
}
.customize-service-margin-intro {
  //padding-top: 25rem;
  padding-top: 5rem;
  padding-bottom: 10rem;
}
// End Page Customize Service


// Start Page Career
.wp-career-banner{
  .place-img:nth-child(2) {padding-top: 9rem;}
  .place-img:nth-child(3) {padding-top: 16rem;}
}
// End Page Career


// Start Page About
.about-page-section-committed {
  position: relative;
  overflow: hidden;

  .bg-img-globe {
    position: absolute;
    //top: 231px;
    //right: 0;
    width: auto;
    //height: 869px;
    object-fit: contain;
    object-position: center;
    z-index: -2;
    opacity: .75;
  }
  .place-to-manipulative {
    position: absolute;
    //right: 0;
    top: 10%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 3.08%, rgba(0, 0, 0, 0.690863) 46.68%, #000 84.32%);
    width: 100%;
    height: 547px;
    z-index: -1;
  }
}

.about-page-section-our-team {
  position: relative;
  overflow: hidden;
  background-color: $dark-300;
  color: $white;

  .bg-img-globe-our-team {
    position: absolute;
    width: auto;
    //object-fit: contain;
    //object-position: center;
    z-index: -2;
    opacity: .75;
    left: -30%;
    //left: -45%;
    //height: 100%;
  }

  .place-to-manipulative {

  }
}

.wp-head-founder {
  .wp-text-gradient-brand-span {
    width: 100%;
  }
}
// End Page About


// Start Page Promo
.wp-head-page-promo {
  position: relative;

  .wp-promo-slider {
    position: unset;
    border-radius: 1.5rem;
    
    .swiper-pagination {
      bottom: 10% !important;
    }
                         
    .swiper-wrapper {
      .swiper-slide {
        height: 75vh;
        max-height: 750px;
        border-radius: 1.5rem;
        overflow: hidden;

        .img-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: $z-1;
          margin: 0;

          .img-cover {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
        
        .img-background-side-linear {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: $z-2;
          background: linear-gradient(90deg, rgba(32, 51, 60, 0.8) -0.81%, rgba(32, 51, 60, 0.4) 50.37%, rgba(32, 51, 60, 0) 100%);
        }
        
        .meta-wrapper {
          padding: 5rem 5rem 5rem 6rem;
          position: absolute;
          top: 0;
          bottom: 0;
          z-index: $z-4;
          color: $light-100;
          width: 100%;
          display: flex;
          justify-content: flex-end;
          flex-direction: column;
        }
      }
    }

  }
}

.wp-category-list {
  .category-list {
    column-gap: 1rem;
  
    span {
      border: 1px solid $dark-100;
      cursor: pointer;
    }
  }
}


.wp-head-page-promo-detail {
  .img-wrapper {
    height: 75vh;
    max-height: 750px;

    .img-cover {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
// End Page Promo


// Start Page Understanding Bill
.wp-slide-bill-info {
  .swiper-wrapper {
    height: auto!important;
    margin-bottom: 3rem;
  }
}
// End Page Understanding Bill



// Start Slide About Timeline
.wp-slide-about-timeline {
  .swiper-wrapper {
    //height: 600px!important;
    height: auto!important;
    //margin-bottom: 6rem;
  }
  //.swiper-pagination {text-align: left;}

  .swiper-pagination-bullets .swiper-pagination-bullet {
    background-color: #fff;
    opacity: 50%;
  }

  .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #fff;
    opacity: 100%;
  }
}
// End Slide About Timeline


// Start Slide Attribute (Pagination on Background Black, Content Center)
.wp-slide-pagination-white {
  .swiper-pagination-bullets .swiper-pagination-bullet {
    background-color: #fff;
    opacity: 50%;
  }

  .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #fff;
    opacity: 100%;
  }
}

.wp-slide-content-center {
  &.swiper-container-autoheight .swiper-wrapper {align-items: center;}
}
.wp-slide-pagination-left {
  .swiper-pagination {text-align: left}
}
// End Slide Attribute





.wp-head-page-general {
  padding-top: 5rem;

  .head-page-general {
    overflow: hidden;
    margin-top: 3rem;
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    //height: 30rem;
    min-height: 30rem;
    background-color: $dark-200;
    @include b-radius-24-px();

    .page-general-bg-illustration {
      position: absolute;
      //opacity: 0.5;
      //top: 10%;
      left: -20%;
      width: calc(100% - 20%);
      z-index: 0;
    }

    .bg-black-overflow-image {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      right: 0;
      transform: matrix(1, 0, 0, -1, 0, 0);
      //background: linear-gradient(82deg, rgba(26, 26, 26, 0) 0.78%, rgb(0 0 0 / 72%) 56.95%, #000000 99.57%);
      background: linear-gradient(82deg, rgba(26, 26, 26, 0) 0.78%, rgb(0 0 0) 56.95%, #000000 99.57%);
    }
  }
}

.hide-box-shadow {box-shadow: unset!important;}

.row-8px > *{
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.a-link-default {color: unset;}
.cursor-pointer {cursor: pointer;}


// Custom Map
.custom-wrap-maps {
  min-height: 90vh;
  height: 100%;
  position: relative;
  width: 100%;
}

.wp-form-coverage-area {
  padding-left: 1.5rem;
  padding-right: 3rem;
}

.coverage-area-package-card {
  position: relative;
  overflow: hidden;
  //background-color: $dark-200;
  background-color: #191919;
  border-radius: 0.75rem;
  padding: 1.5rem;
  color: $white;

  .tag-sub {
    font-size: 12px;
    margin-top: -3px;
  }

  .sub-title {

  }

  .space {
    width: 100%;
    height: 100%;
    margin-top: 2rem;
    z-index: 100;
    position: relative;
  }

  .product-speed {
    //font-size: 2.5rem;
    font-size: 40px;
    font-weight: 600;
  }

  .product-unit {
    //font-size: 1.5rem;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
  }

  .product-sub-unit {
    font-weight: 400;
  }

  img {
    position: absolute;
    right: -20%;
    top: 0;
    width: 100%;
    opacity: .75;
    //z-index: -1;
  }
}

.modal-coverage-area-right-position {
  .modal-dialog {
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    position: relative;
    height: 100%;
    max-width: 65%;

    .modal-content {
      height: 100%;
      overflow: auto;
      border-radius: 0;
      border: 0;
    }
  }

  .modal-header {
    border-bottom: 0;
    padding: 2rem;
  }

  .modal-body {
    padding: 0 2rem 2rem;
  }
}


.coverage-area-card-choose {
  position: relative;
  padding: 1.5rem;
  background-color: $light-300;
  color: #000;
  transition: all 0.3s ease-in-out;
  border-radius: .5rem;
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0);

  &:hover, &.active {
    border: 1px solid #000;
    box-shadow: 0px 1px 6px 0px #00000024;
    cursor: pointer;
  }

  .place-check {
    position: absolute;
    right: 1rem;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 100%;
    background-color: #fff;
    border: 2px solid #D9D9D9;
    //color: #000;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
      color: #fff;
      background-color: #000;
      border: unset;
    }
  }

  .space {
    width: 100%;
    //height: 100%;
    margin-top: 1rem;
    z-index: 100;
    position: relative;
  }

  .product-speed {
    font-size: 2rem;
    font-weight: 600;
  }

  .product-unit {
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
  }

  .product-sub-unit {font-weight: 400;}
}

.coverage-area-card-product-horizontal {
  padding: 1.5rem;
  background-color: $light-300;
  color: #000;
  transition: all 0.3s ease-in-out;
  border-radius: .5rem;
  overflow: hidden;
  &:hover {
    border: 1px solid #000;
    cursor: pointer;
  }

  .product-speed {
    font-size: 40px;
    font-weight: 600;
  }

  .product-unit {
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
  }

  .product-sub-unit {font-weight: 400;}
}

// Dropdown animation start
@keyframes dropdownAnimation {
  0% {
    opacity: 0;
    transform: translate3d(0,-30px,0);
  }
  100% {
    opacity: 1;
    transform: none;
    transform: translate3d(0,0,0);
  }
}
// Dropdown animation End

@import "partial";
@import "typography";

@import "responsive";
