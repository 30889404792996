// START LOCOMOTIVE
html.has-scroll-smooth {
  backface-visibility: hidden;
  //transform: translateZ(0);
}

[data-load-container] {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
}

[data-scroll-container],
[data-scroll-section]
{
  perspective: 1px;
}

a {text-decoration: none;}

//#myNavbar {
//  position: fixed;
//  top: 0; left: 0;
//  transform: translate3d(0,0,0);
//  transition: transform .5s ease-in-out;
//}
//
//html[data-direction="down"] #myNavbar {
//  transform: translate3d(0,-100%,0);
//}
// END LOCOMOTIVE


//* {
//  margin: 0;
//  padding: 0;
//  box-sizing: border-box;
//}
//html.has-scroll-smooth {
//  overflow: hidden;
//}
//html.has-scroll-dragging {
//  -webkit-user-select: none;
//  -moz-user-select: none;
//  -ms-user-select: none;
//  user-select: none;
//}
//.has-scroll-smooth body {
//  overflow: hidden;
//}
//.has-scroll-smooth [data-scroll-container] {
//  min-height: 100vh;
//}
//[data-scroll-direction="horizontal"] [data-scroll-container] {
//  height: 100vh;
//  display: inline-block;
//  white-space: nowrap;
//}
//[data-scroll-direction="horizontal"] [data-scroll-section] {
//  display: inline-block;
//  vertical-align: top;
//  white-space: nowrap;
//  height: 100%;
//}
//.c-scrollbar {
//  position: absolute;
//  right: 0;
//  top: 0;
//  width: 11px;
//  height: 100%;
//  transform-origin: center right;
//  transition: transform 0.3s, opacity 0.3s;
//  opacity: 0;
//}
//.c-scrollbar:hover {
//  transform: scaleX(1.45);
//}
//.c-scrollbar:hover,
//.has-scroll-scrolling .c-scrollbar,
//.has-scroll-dragging .c-scrollbar {
//  opacity: 1;
//}
//[data-scroll-direction="horizontal"] .c-scrollbar {
//  width: 100%;
//  height: 10px;
//  top: auto;
//  bottom: 0;
//  transform: scaleY(1);
//}
//[data-scroll-direction="horizontal"] .c-scrollbar:hover {
//  transform: scaleY(1.3);
//}
//.c-scrollbar_thumb {
//  position: absolute;
//  top: 0;
//  right: 0;
//  background-color: black;
//  opacity: 0.5;
//  width: 7px;
//  border-radius: 10px;
//  margin: 2px;
//  cursor: -webkit-grab;
//  cursor: grab;
//}
//.has-scroll-dragging .c-scrollbar_thumb {
//  cursor: -webkit-grabbing;
//  cursor: grabbing;
//}
//[data-scroll-direction="horizontal"] .c-scrollbar_thumb {
//  right: auto;
//  bottom: 0;
//}

.modal-open { [data-scroll-container], [data-scroll-section] {
    perspective: unset!important;
  }
}