.b-rad-4 {@include b-radius-4-px;}
.b-rad-8 {@include b-radius-8-px;}
.b-rad-16 {@include b-radius-16-px;}
.b-rad-24 {@include b-radius-24-px;}
.b-rad-32 {@include b-radius-32-px;}
.b-rad-40 {@include b-radius-40-px;}
.b-rad-48 {@include b-radius-48-px;}
.b-rad-64 {@include b-radius-64-px;}

.b-rad-top-32 {
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
}

.section-radius-top {
  //border-top-left-radius: 2rem;
  //border-top-right-radius: 2rem;
  border-top-left-radius: 1.875rem;
  border-top-right-radius: 1.875rem;
}

.opacity-50-percent {opacity: .5}
.opacity-60-percent {opacity: .6}

.z-index-min-99 {z-index: -999}


.use-arrow-active {
  &.active {
    transform: rotate(-90deg);
  }
  transition: all 0.12s ease;
}

.use-arrow-180 {
  transform: rotate(180deg);
  transition: all 0.12s ease;
  &.active {
    transform: rotate(0deg);
  }
}

.accordion-arrow-180 {
  transition: all 0.12s ease;

  &.active {
    .arrow-icon {
      transition: all 0.12s ease;
      transform: rotate(360deg);
    }
  }

  .arrow-icon {
    transition: all 0.12s ease;
    transform: rotate(180deg);
  }
}

.sub-accordion-arrow-180 {
  transition: all 0.12s ease;

  &.active {
    .sub-arrow-icon {
      transition: all 0.12s ease;
      transform: rotate(360deg);
    }
  }

  .sub-arrow-icon {
    transition: all 0.12s ease;
    transform: rotate(180deg);
  }
}



.packages-and-faq {
  position: relative;

  .illustration-globe {
    position: absolute;
    top: 31px;
    z-index: -999;
    width: 100%;
    height: 869px;
    object-fit: contain;
    object-position: center;
  }

  .img-linear-background {
    position: absolute;
    top: 463px;
    z-index: -998;
    background: linear-gradient(
                    180deg,
                    rgba(255, 255, 255, 0) -60.18%,
                    #ffffff 84.32%
    );
    width: 100%;
    height: 437px;
  }

  .packages {
    padding: 275px 0 119px 0;
    overflow: hidden;

    .packages-text-slider {
      gap: 250px;

      h1 {
        font-size: 300px;
        font-weight: 500;
        color: $tint-200;
        white-space: nowrap;
      }
    }

    h2 {
      font-size: 2.5rem;
      font-weight: 500;
      margin-bottom: 45px;
      max-width: 526px;
    }

    p {
      font-weight: 400;
      margin-bottom: 44px;
    }

  }

  .faq {
    margin-bottom: 225px;

    .af-description {
      font-weight: 400;
      color: $dark-300;
      margin-bottom: 44px;
    }

    .faq-list {
      .faq-list-border {
        border-bottom: 1px solid #000000b3;
      }

      sup {
        font-size: 0.875rem;
        font-weight: 500;
        color: $dark-300;
      }

      .card {
        .card-header {
          h5 {
            font-size: 26px;
            font-weight: 400;
          }

          svg {
            height: 46px;
            width: 46px;
          }

          &:hover {
            cursor: pointer !important;
          }
        }
      }
    }

    button {
      font-weight: 600;
      gap: 92px;
      padding: 10px;
      margin-top: 60px;
    }

    h2 {
      margin-top: 241px;

      &.text-tagline {
        font-size: 70px;
        font-weight: 500;
      }
    }
  }
}


.wrap-accordion-faq-arrow-diagonal {
  padding-bottom: 225px;

  .af-description {
    * {
      font-weight: 400;
      color: $dark-300;
    }
  }

  .faq-list {
    .faq-list-border {
      border-bottom: 1px solid #000000b3;
    }

    sup {
      font-size: 0.875rem;
      font-weight: 500;
      color: $dark-300;
    }

    .card {
      .card-header {
        h5 {
          font-size: 1.625rem;
          font-weight: 400;
        }

        svg {
          height: 46px;
          width: 46px;
        }

        &:hover {
          cursor: pointer !important;
        }
      }

      .wp-faq-card-body {
        * {
          margin-bottom: 1.5rem;
        }
      }
    }
  }

  //button {
  //  font-weight: 600;
  //  gap: 92px;
  //  padding: 10px;
  //  margin-top: 60px;
  //}

  h2 {
    margin-top: 241px;

    &.text-tagline {
      font-size: 70px;
      font-weight: 500;
    }
  }
}


.bg-img-section {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: -1;
  position: absolute;
}


// Hover Action
.wp-hover-arrow {
  .wp-arrow {
    display: none;
    transition: all 0.5s ease;
    &:hover {display: block}
  }
}

.cursor-pointer {cursor: pointer;}


// Hover Action Circle
.wp-hover-circle-primary {
  position: absolute;
  width: 100px;
  height: 100px;
  top: 50%;
  left: 50%;
  margin: -50px 0 0 -50px;
  //background: $tint-300;
  background: $tint-soft;
  color: $dark-200;
  border-radius: 80%;
  backface-visibility: hidden;
  visibility:hidden;
  z-index:99999;


  display: flex;
  justify-content: center;
  align-items: center;
}


// Section Coverage
.coverage-area-section {
  //padding: 116px 0 160px 0;
  background-color: $light-200;
  //border-radius: 26px 26px 0 0;
  position: relative;
  overflow: hidden;

  .bg-icon-pills-gray {
    position: absolute;
    top: 363px;
    right: 0;
    left: 0;
    width: 100%;
    height: 884px;
    object-fit: cover;
    object-position: center;
  }

  .img-linear-background {
    position: absolute;
    top: 363px;
    left: 0;
    right: 0;
    width: 100%;
    height: 615px;
    background: linear-gradient(180deg, #FAFAFA 0%, rgba(250, 250, 250, 0) 78.24%);
  }

  h5 {
    //font-size: $text-2xl;
    font-weight: 500;
    color: $dark-100;
  }

  h2 {
    font-size: 4.375rem;
    font-weight: 500;
    color: $dark-100;
    max-width: 462px;
  }

  .coverage-area-bg {
    //margin-top: 103px;
    background-color: $dark-300;
    //border-radius: 20px;
    //padding: 50px 87px 50px 50px;
    position: relative;
    z-index: 10;

    img {
      &.coverage-img {
        width: 100%;
        //height: 426px;
        //height: 420px;

        //height: 26.25rem;

        object-fit: cover;
        object-position: center;
        //border-radius: 20px;

        //width: 100%;
        //object-fit: cover;
        //object-position: center;
        height: 100%;
      }
    }

    //.coverage-img {
    //  width: 100%;
    //  //height: 426px;
    //  //height: 420px;
    //  height: 26.25rem;
    //  object-fit: cover;
    //  object-position: center;
    //  //border-radius: 20px;
    //}

    h2 {
      font-size: 3rem;
      font-weight: 500;
      color: $light-100;
      max-width: 490px;

      span {
        background: linear-gradient(90deg, $primary-theme 0%, $orange-200 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}

// Custom Opacity
.opacity-80 {
  opacity: .80 !important;
}


// Custom rotate
.rotate-45 {
  transform: rotate(45deg);
}


// Space top
.mt-1rem {margin-top: 1rem}
.mt-2rem {margin-top: 2rem}
.mt-3rem {margin-top: 3rem}
.mt-5rem {margin-top: 5rem}
.mt-8rem {margin-top: 8rem}

// Space Bottom
.mb-8rem {margin-bottom: 8rem}
.mb-5rem {margin-bottom: 5rem}


// Space top negative
.mt-n-1rem {margin-top: -1rem}
.mt-n-2rem {margin-top: -2rem}
.mt-n-3rem {margin-top: -3rem}
.mt-n-5rem {margin-top: -5rem}
.mt-n-8rem {margin-top: -8rem}


//.image-holder .img-1 {
//  filter: grayscale(1);
//  transition: 0.5s ease-in-out;
//}
//.image-holder:hover .img-1 {
//  filter: grayscale(0);
//  transition: 0.5s ease-in-out;
//}

.image-holder {
  img {
    filter: grayscale(1);
    transition: 0.5s ease-in-out;
  }

  &:hover img {
    filter: grayscale(0);
    transition: 0.5s ease-in-out;
  }
}


.position-fix-center {
  display: flex;
  justify-content: center;
  align-items: center;
}


.nav-lang-btn {
  cursor: pointer;
  font-size: 0.875rem;
  transition: opacity 0.3s;
  &.active {font-weight: 600;}
  &:hover {opacity: 0.7;}
}


.input-searching-faq {
  background-color: $light-200;
  padding: 1.25rem 2rem 1.25rem;
  border: 0;
  font-size: 1.2rem;
}

// CUSTOM TAB
.tab-underscore {
  flex-direction: row;
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  overflow-y: hidden;
  display: block;

  .nav-item {
    //margin-right: 1rem;
    padding-bottom: 0.5rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    display: inline-block;
    min-width: 20%;
    //padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);

    //&.active {
    //  border-bottom: 1px solid #000;
    //}
  }

  .nav-link {
    //font-size: 0.875rem;
    //border-color: transparent !important;
    //display: inline-block;

    font-weight: 400;
    color: $dark-100 !important;
    opacity: .5;
    //border-width: 0;
    border-left: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid $dark-400;

    //padding-top: 1rem;
    //padding-bottom: 1rem;
    //padding-left: 1rem;
    //padding-right: 1rem;

    padding: 1rem 1.5rem;

    //padding-left: 0;
    //padding-right: 0;

    &.active {
      color: $dark-100 !important;
      opacity: 1;
      background-color: unset;
      border-bottom: 1px solid $dark-100;
    }
  }
}

.long-tab {
  overflow-x: auto;
  //display: block;
  white-space: nowrap;
  overflow-y: hidden;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}


/* Tabs : Simple
------------------------------------
*/
@mixin config_tab_color($passColorTab) {
  &.nav-tabs-simple > li > a:after {background-color: $passColorTab;}
  &.nav-tabs-simple > li > button:after {background-color: $passColorTab;}
}

//.nav-tabs {
//  position: relative;
//
//  .nav-link{
//    font-size: 0.875rem;
//    font-weight: 400;
//    color: $dark-300!important;
//    border-color: transparent!important;
//    border-width: 0;
//
//    &:hover {
//      color: unset;
//      border-color: unset!important;
//
//    }
//  }
//
//  > li > a {
//    display: block;
//    border-radius: 0;
//    padding: 13px 20px;
//    margin-right: 0;
//    font-weight: 500;
//    letter-spacing: 0.06em;
//    color: rgba(122, 137, 148, 0.7);
//    font-size: 10.5px;
//    min-width: 70px;
//    text-transform: uppercase;
//    border-color: transparent;
//    position: relative;
//    line-height: 1.7em;
//  }
//}
//.tab-content {
//  > .tab-pane {display: none;}
//  > .active {display: block;}
//}

//.tab-pane {
//  padding: 1.5rem 1.25rem 1.25rem;
//  //.row>* {
//  //  padding-left: calc(#{$bs-gutter-x-sm} * .5);
//  //  padding-right: calc(#{$bs-gutter-x-sm} * .5);
//  //}
//
//  .card {margin-bottom: 1.563rem;}
//}

.nav-tabs-simple {border-bottom: 0;}

//.nav-tabs-simple:after {
//  content: '';
//  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
//  width: 100%;
//  position: absolute;
//  bottom: -2px;
//  z-index: 120;
//  left: 0;
//}

.nav-tab-use-underscore {
  overflow-x: auto;
  white-space: nowrap;
  overflow-y: hidden;
  //border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  flex-direction: row;
  width: 100%;

  display: block;

  .nav-item {
    display: inline-block;
    //min-width: 30%;
    padding: 0.5rem 0.5rem 0;
    min-width: 25%;
  }

  .nav-link {
    border: unset;
    padding: 16px 12px;
    color: #000;
    opacity: .5;

    &.active, &:hover {
      opacity: 1;
    }
  }
}

.nav-tabs-simple > li {margin-bottom: 0;}
.nav-tabs-simple > li > a:after, .nav-tabs-simple > li > button:after {
  position: absolute;
  -webkit-transition: all 0.1s linear 0s;
  transition: all 0.1s linear 0s;
  -webkit-backface-visibility: hidden;
  width: 100%;
  display: block;
  //background-color: $danger-200;
  height: 0;
  content: '';
  left: 0;
  z-index: 125;
  top: 100%;
}
.nav-tabs-black {@include config_tab_color($dark-100);}

.nav-tabs-primary {

  .nav-link {
    position: relative;

    &.active {
      color: $primary-theme;
      font-weight: 500;
    }

    &:hover {
      color: $primary-theme;
      font-weight: 500;

      &:after {
        //position: absolute;
        //-webkit-transition: all 0.1s linear 0s;
        //transition: all 0.1s linear 0s;
        //-webkit-backface-visibility: hidden;
        //width: 100%;
        //display: block;
        //background-color: $primary-theme;
        //content: '';
        //left: 0;
        //z-index: 125;
        //bottom: 0;
        height: 3px;
      }
    }
  }

  > li > .nav-link:after {
    //background-color: $primary-theme;
    position: absolute;
    -webkit-transition: all 0.1s linear 0s;
    transition: all 0.1s linear 0s;
    -webkit-backface-visibility: hidden;
    width: 100%;
    display: block;
    background-color: $primary-theme;
    height: 0px;
    content: '';
    left: 0;
    z-index: 125;
    bottom: 0;
    //top: 100%;
    //height: 3px;
  }

  > li > .nav-link.active:after {
    height: 3px;
  }

  //@include config_tab_color($primary-theme);

  //&.nav-tabs-simple > li > button:after {
  //  //background-color: $primary-theme;
  //  position: absolute;
  //  -webkit-transition: all 0.1s linear 0s;
  //  transition: all 0.1s linear 0s;
  //  -webkit-backface-visibility: hidden;
  //  width: 100%;
  //  display: block;
  //  background-color: $primary-theme;
  //  height: 0px;
  //  content: '';
  //  left: 0;
  //  z-index: 125;
  //  top: 100%;
  //  //height: 3px;
  //}
  //
  //&.nav-tabs-simple > li > button.active:after {
  //  height: 3px;
  //}

  //> li > a:after {
  //  position: absolute;
  //  -webkit-transition: all 0.1s linear 0s;
  //  transition: all 0.1s linear 0s;
  //  -webkit-backface-visibility: hidden;
  //  width: 100%;
  //  display: block;
  //  background-color: $primary-theme;
  //  height: 0px;
  //  content: '';
  //  left: 0;
  //  z-index: 125;
  //  top: 100%;
  //}

  //> li > a.active:after, .nav-tabs-simple > li > a:hover:after {
  //  height: 3px;
  //}

}


//.nav-tabs-success {@include config_tab_color($primary-theme);}
//.nav-tabs-primary-brand {@include config_tab_color($primary-theme);}
//.nav-tabs-complete {@include config_tab_color($success-200);}
//.nav-tabs-danger {@include config_tab_color($danger-200);}
//.nav-tabs-warning {@include config_tab_color($warning-200);}
//.nav-tabs-info {@include config_tab_color($neutral-300);}

//.nav-tabs-simple > li > a.active:hover,
//.nav-tabs-simple > li > a.active:focus {
//  background-color: transparent;
//  border-color: transparent;
//}
//.nav-tabs-simple > li > a.active:after,
//.nav-tabs-simple > li > a:hover:after {
//  height: 3px;
//}
//.nav-tabs-simple.nav-tabs-left:after,
//.nav-tabs-simple.nav-tabs-right:after {
//  border-bottom: 0px;
//}
//.nav-tabs-simple.nav-tabs-left > li > a:after,
//.nav-tabs-simple.nav-tabs-right > li > a:after {
//  width: 0px;
//  height: 100%;
//  top: 0;
//  bottom: 0;
//  position: absolute;
//}
//.nav-tabs-simple.nav-tabs-left > li > a.active:after,
//.nav-tabs-simple.nav-tabs-right > li > a.active:after,
//.nav-tabs-simple.nav-tabs-left > li > a:hover:after,
//.nav-tabs-simple.nav-tabs-right > li > a:hover:after {
//  width: 3px;
//}
//.nav-tabs-simple.nav-tabs-left > li > a:after {
//  right: 0;
//  left: auto;
//}
//.nav-tabs-simple.nav-tabs-right > li > a:after {
//  left: 0;
//}
//
//.dropdown-item:focus {
//  background-color: $primary-theme;
//
//}


.nav-item {
  .dropdown-item {
    &.active, &:active {
      background-color: transparent;
      color: #212529;
    }
  }
}



.contact-data {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 2.5rem 2rem;
  margin: 1rem 0;
  border-radius: 0.5rem;
  background-color: $light-200;
  color: #000;

  .data-thumbnail {
    display: inline-block;
    flex-shrink: 0;
    position: relative;
    border-radius: 0.46rem;
    margin-right: 2rem;
    background-color: $white;
    padding: 1rem;
    img {
      width: 32px;
      height: 32px;
    }
  }

  .data-content {
    justify-content: space-between;
    flex: 1 auto;
  }

  .data-icon {
    display: inline-block;
    flex-shrink: 0;
    position: relative;
    border-radius: 100%;
    background-color: $white;
    padding: .5rem;
  }
}

.btn-gx-theme {
  min-width: 200px;
  //display: flex;
  display: inline-flex;
  font-weight: 600;
  align-items: center;
}


.w-55-percent {width: 55%;}
.w-85-percent {width: 85%;}

.swiper-hide-pagination {
  .swiper-pagination {display: none;}
}

.widget-visible {
  .hide-tawk-button {
    display: none !important;
  }
}

.z-index-hide-chat {
  z-index: 3000000000 !important;
}

.z-index-in-modal {
  z-index: 4000000000 !important;
}

.icon-32 {
  width: 32px;
  height: 32px;
}

.icon-48 {
  width: 48px;
  height: 48px;
}

.icon-64 {
  width: 64px;
  height: 64px;
}

pre {
  white-space: break-spaces;
}

.padding-x-card-84 {
  padding-left: 84px;
  padding-right: 84px;
}

// Logo Product
.w-logo-product {
  width: 68px;
}

.w-logo-product-signature {
  width: 160px;
}