// ==> Border
@mixin b-radius-4-px {border-radius: 0.25rem;}
@mixin b-radius-8-px {border-radius: 0.5rem;}
@mixin b-radius-16-px {border-radius: 1rem;}
@mixin b-radius-24-px {border-radius: 1.5rem;}
@mixin b-radius-32-px {border-radius: 2rem;}
@mixin b-radius-40-px {border-radius: 2.5rem;}
@mixin b-radius-48-px {border-radius: 3rem;}
@mixin b-radius-64-px {border-radius: 4rem;}

@for $i from 1 through 9 {
  .fw-#{$i * 100} {
    font-weight: ($i * 100);
  }
}