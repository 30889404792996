.menu_container {
  display: flex;
  align-items: center;
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background-color:#fff;
  color: #000;
  flex-direction: column;
  padding-top: 100px;
    
  .wp-menu-mobile {
    width: 100%;
    height: 100%;
    padding-bottom: 130px;
    overflow-y: scroll;

      .wp-btn-dropdown {
        border-bottom: 1px solid rgb(0 0 0 / 24%);

        .dropdown-toggle {
          svg {
            &.arrow-dropdown-icon {
              height: 22px;
              width: 22px;
              padding: 4px;
              transform: rotate(0deg) !important;
              transition: transform .3s ease !important;
            }
          }
  
          &::after {
            display: none;
          }
  
          &.show {
            svg {
              &.arrow-dropdown-icon {
                transform: rotate(-180deg) !important;
              }
            }
          }
        }
      
        a:not(.dropdown-menu.show .dropdown-item) {
          display: inline-flex;
          color: #000 !important;
          text-decoration: none;
          height: auto;
          padding: 0.825rem 0;
          overflow: hidden;
          font-weight: 500;
          width: 100%;
          position: relative;
          transition: none;
    
          @media(max-width: 768px) {
            font-size: 2rem;
          }
      
          + a {
            margin-top: 1vw;
          }
    
          &.active {
            &::after {
              content: "";
              position: absolute;
              background: #000;
              left: auto;
              right: 0.4em;
              top: 50%;
              bottom: auto;
              width: .5rem;
              height: .5rem;
              transform: translate(-50%, -50%) scale(1) rotate(0.001deg);
              display: block;
              border-radius: 50%;
              transition: all .3s cubic-bezier(.7, 0, .3, 1);
              will-change: transform;
            }
          }
        }
      }

      .nav-item {
        &:last-child {
          .wp-btn-dropdown {
            border-bottom: none !important;
          }
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .wp-menu-mobile-footer {
      position: fixed;
      bottom: 0;
      background-color: inherit;
    }
}
