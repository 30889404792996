@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$primary-theme: #f9c900;

$dark-100: #000;
$dark-200: #1A1A1A;
$dark-300: #171718;
$dark-400: #38383C;
$dark-500: #808080;
$dark-600: #A6A6A6;
$dark-700: #4F4F4F;

$light-100: #FFFFFF;
$light-200: #FAFAFA;
$light-300: #F5F5F5;
$light-400: #DFDFDF;
$light-500: #F2F2F2;
$light-600: #F0F0F0;
$light-700: #F7F7F7;

$tint-100: #614E00;
$tint-200: #836900;
$tint-300: #f9c900;
$tint-600: #FBD94D;
$tint-700: #FFFAE5;
$tint-800: #E0D39B;
$tint-soft: #FFF9E2;

$theme-colors: (
        'primary-theme':  $primary-theme,
        'dark-100':  $dark-100,
        'dark-200':  $dark-200,
        'dark-300':  $dark-300,
        'dark-400':  $dark-400,
        'dark-500':  $dark-500,
        'dark-600':  $dark-600,
        'dark-700':  $dark-700,

        'light-100':  $light-100,
        'light-200':  $light-200,
        'light-300':  $light-300,
        'light-400': $light-400,
        'light-500': $light-500,
        'light-600': $light-600,
        'light-700': $light-700,

        'tint-100':   $tint-100,
        'tint-200':   $tint-200,
        'tint-300':   $tint-300,
        'tint-600':   $tint-600,
        'tint-700':   $tint-700,
        'tint-800':   $tint-800,
        'tint-dark': #090700,
        'tint-soft': #FFF9E2,

        'danger': $danger
);

// font family
//$font-family-base: 'Neue Haas Grotesk Display Pro';
//$font-family-sans-serif: 'Neue Haas Grotesk Display Pro';
//$font-family-monospace: 'Neue Haas Grotesk Display Pro';

$font-family-base: 'Neue Haas Grotesk Display Pro', sans-serif;
$font-family-sans-serif: 'Neue Haas Grotesk Display Pro', sans-serif;
$font-family-monospace: 'Neue Haas Grotesk Display Pro', sans-serif;



$container-max-widths: (
       sm: 540px,
       md: 720px,
       lg: 960px,
       xl: 1140px,
       xxl: 1320px
);


// font-size
$text-xs: 0.75rem; // 12px
$text-sm: 0.875rem; // 14px
$text-base: 1rem; // 16px
$text-lg: 1.125rem; // 18px
$text-xl: 1.25rem; // 20px
$text-2xl: 1.5rem; // 24px
$text-3xl: 1.875rem; // 30px

// z-index
$z-9999: 9999;
$z-1099: 1099;
$z-999: 999;
$z-n-999: -999;
$z-n-998: -998;
$z-4: 4;
$z-n-4: -4;
$z-2: 2;
$z-n-2: -2;
$z-1: 1;
$z-n-1: -1;

$enable-cssgrid: true
