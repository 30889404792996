// .carousel-images-custom {
//     position: relative;
//     height: 500px;
//     width: 100%;
//     max-width: 100%;
//     min-height: 100vh;
//     max-height: 100%;
//     overflow: hidden;
  
//     img {
//         width: 100%;
//         height: 100%;
//         object-fit: cover;
//         object-position: center;
//     }

//     .overflow-background {
//         position: absolute;
//         top: 0;
//         left: 0;
//         right: 0;
//         bottom: 0;
//         z-index: 2;
//         //background: rgb(0 0 0 / 50%);
//         background-color: rgb(25 21 1 / 56%);
//     }
// }

.carousel-custom {
    .fade-slider {
        position: relative;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        
        .slider-item {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        .overflow-background {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
            //background: rgb(0 0 0 / 50%);
            background-color: rgb(25 21 1 / 56%);
        }
    }

    .nav-tabs,
    .tab-content {
        position: absolute;
        left: unset;
        right: unset;
        z-index: 2;
        width: 100%;
    }

    .nav-tabs {
        top: calc(100vh - 75%);
    }

    .tab-content {
        bottom: calc(100vh - 75%);
    }
}
  



