// min-width 1610px
@media screen and (min-width: 1610px) {
  .section-head-coverage-area {
    .illustration-globe {
      top: unset;
      bottom: -20%;
    }

    .img-linear-background {
      top: unset;
      bottom: -15%;
    }

    .illustration-globe-left {
      top: unset;
      bottom: -20%;
    }

    .img-linear-background-left {
      top: unset;
      bottom: -5%;
    }

    .text-head-wrapper {
      position: absolute;
      right: 0;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &.new {
      height: 100vh !important;
    }
  }

  .form-check-coverage-new {
    .custom-maps {
      top: -13%;
    }
  }

  .wp-head-page-promo {
    .wp-promo-slider {
      .swiper-pagination {
        bottom: 6% !important;
      }
    }
  }

  .sc-home-tab-story {
    .nav-tabs {
      top: calc(100vh - 70%);
    }

    .tab-content {
      bottom: calc(100vh - 70%);
    }
  }
}

// max-width 1440px
@include media-breakpoint-down(xxl) {
  .product-signature-tagline {
    .bg-icon-pills-dark {
      height: 958px;
    }
  }
}

// max-width 1024px
@include media-breakpoint-down(xl) {
  .section-head-of-page {
    .hero-text-wrapper {
      max-width: 750px;

      .hero-text, .hero-text-transform {
        font-size: 4.063rem; // 65px
      }
    }
  }

  .my-swiper-package {
    .swiper-slide {
      &.card-package {
        .product-title {
          font-size: 1.625rem !important; // 26px
        }

        .priority-services {
          margin-top: 66px;
        }

        .product-speed {
          font-size: 3rem; // 48px
        }

        span {
          font-size: $text-xl;
        }
      }
    }
  }

  .my-swiper-article {
    .swiper-slide {
      &.card {
        .card-footer {
          h3 {
            font-size: 1.5rem;
          }
        }
      }
    }
  }

  .gx-app-promo {
    .card {
      &.card-mobile-app {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
      }
    }
  }

  .my-partner {
    .partner-slideshow {
      .marquee {
        .card-partner {
          padding: 2rem 1.5rem;
        }
      }
    }
  }

  footer {
    .sitemap {
      h2 {
        font-size: 2.5rem;
        line-height: unset;
      }

      .bg-rectangle-sitemap {
        width: 400px;
        height: 550px;
      }
    }
  }
}

// max-width: 768px
@include media-breakpoint-down(lg) {
  body.has-scroll-menu {
    overflow: hidden;
  }

  .container, 
  .container-fluid {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .wp-nav-mobile {
    .navbar-nav {
      .nav-item {
        &.dropdown {
          .dropdown-menu {
            position: static !important;
            transform: none !important;
            z-index: 0 !important;
            
            &.show {
              animation-name: fadeIn;
              animation-duration: .6s;
              animation-delay: .2s;
              animation-fill-mode: both;
            }
          }
        }
      }

      &.btn-login-container {
        margin-left: 0;

        .btn-login {
          font-size: $text-lg;
          font-weight: 400;
          color: $dark-100;
          background-color: transparent;
          padding: 0.5rem 2rem;
          border: 1px solid $dark-100;
          border-radius: 0.25rem;
          transition: all .3s ease-in-out;

          &:hover {
            background-color: $primary-theme;
            color: $dark-100;
            border: 1px solid $primary-theme;
          }
        }
      }
    }

    .slc-container {
      .slc-prefix__control {
        width: 100%;
        height: 42px;
        text-align: center;
        border: none;
        background: $light-500;
      }

      .slc-prefix__menu {
        text-align: center;
      }

      .slc-prefix__input-container {
        justify-content: center;
      }

      .slc-prefix__single-value,
      .slc-prefix__placeholder,
      .slc-prefix__control svg.map-icon,
      .slc-prefix__indicator,
      .slc-prefix__input-container {
        color: $dark-100 !important;
        font-size: $text-base;
        font-weight: 600;
      }
    }
  }

  // header {
    .navbar {
      .navbar-nav {
        .nav-item {
          .nav-link {
            padding: .8rem 0;
            color: $dark-100!important;

            &:hover {
              &::after {
                display: none;
              }
            }

            &.text-content-blur {
              &:hover {
                &::after {
                  display: none;
                }
              }
            }
          }

          &.active {
            .nav-link {
              font-weight: 600;

              &::after {
                display: none;
              }

              &.text-content-blur {
                &::after {
                  display: none;
                }
              }
            }
          }
        }

        &.btn-login-container {
          margin-left: 0;

          .btn-login {
            font-size: $text-sm;
            font-weight: 400;
            color: $dark-100;
            background-color: transparent;
            padding: 0.5rem 2rem;
            border: 1px solid $dark-100;
            border-radius: 0.25rem;
            transition: all .3s ease-in-out;

            &:hover {
              background-color: $primary-theme;
              color: $dark-100;
              border: 1px solid $primary-theme;
            }
          }
        }

        &.space-language {
          margin-left: 0;

          &::before {
            display: none;
          }
        }
      }

      .navbar-toggler {
        //width: clamp(3em, 4vw, 4em);
        //height: clamp(3em, 4vw, 4em);

        width: clamp(2.5em, 4vw, 4em);
        height: clamp(2.5em, 4vw, 4em);

        z-index: $z-1099;
        padding: 0;
        border-radius: 50%;

        .line-wrapper {
          position: relative;
          height: 100%;
          width: 100%;
  
          .custom-toggler-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            transition: all 0.4s ease;
            -webkit-transition: all 0.4s ease;
            -moz-transition: all 0.4s ease;
            display: block;
            width: 22px;
            height: 2px;
            border-radius: 8px;
  
            &.white {
              background-color: #fff !important;
            }
  
            &.black {
              background-color: #000 !important;
            }
  
            &:first-child {
              top: 42%;
            }
  
            &:nth-child(2) {
              top: 50%;
            }
  
            &:last-child {
              top: 55%;
            }
          }
        }

        &:focus {
          box-shadow: none;
        }

        &:not(.collapsed) {
          .line-wrapper {
            .custom-toggler-icon {
              top: 50%;
              left: 50%;
  
              &:first-child {
                transform: translate(-50%, -50%) rotate(45deg);
                -webkit-transform: translate(-50%, -50%)
                rotate(45deg);
                -moz-transform: translate(-50%, -50%) rotate(45deg);
              }
  
              &:nth-child(2) {
                opacity: 0;
                transform: translate(0) rotate(0deg);
                -webkit-transform: translate(0) rotate(0deg);
                -moz-transform: translate(0) rotate(0deg);
              }
  
              &:last-child {
                transform: translate(-50%, -50%) rotate(-45deg);
                -webkit-transform: translate(-50%, -50%)
                rotate(-45deg);
                -moz-transform: translate(-50%, -50%) rotate(-45deg);
              }
            }
          }
        }

        &.custom-navbar-toggler {
          background-color: #131312;
          transition: background-color .3s cubic-bezier(0.36, 0, 0.66, 0),
          box-shadow .3s cubic-bezier(0.36, 0, 0.66, 0);
          z-index: $z-1099;
        }

        &.fixed-menu-mobile {
          position: fixed;
          right: 3rem;
          top: 1.4rem;
          z-index: $z-9999;
          box-shadow: inset 0px 0px 0px 1px rgba(235, 235, 235, 0.65);

          &.has-scrolled {
            background-color: $light-100;

            .line-wrapper {
              .custom-toggler-icon {
                background-color: $dark-100;
              }
            }
          }
        }

        &:not(.custom-navbar-toggler) {
          transition: background-color .3s cubic-bezier(0.36, 0, 0.66, 0),
          box-shadow .3s cubic-bezier(0.36, 0, 0.66, 0);
        }

        &.collapsed {
          & ~ .navbar-collapse {
            transition: right .4s ease-in-out !important;
          }
        }
      }

      .navbar-collapse {
        width: 100%;

        &.custom-sidenav {
          position: fixed;
          top: 0;
          right: -100%;
          height: 100vh;
          overflow: hidden auto;
          background: $light-100;
          padding: 118px 1rem 1rem;

          .slc-container {
            .slc-prefix__control {
              width: 100%;
              border-width: 1px;
              border-color: rgba(0, 0, 0, 0.2);
            }

            .slc-prefix__single-value,
            .slc-prefix__placeholder,
            .slc-prefix__control svg.map-icon,
            .slc-prefix__indicator,
            .slc-prefix__input-container {
              color: $dark-100 !important;
            }
          }

          .navbar-nav {
            &.btn-login-container {
              &::before {
                display: none;
              }
            }
          }
        }

        &.show {
          display: none;
        }
      }

    }

    .border-navbar,
    .border-navbar-black {
      border-bottom: none !important;
    }

    .blur-background {
      background-color: transparent !important;
      transition: none !important;
      box-shadow: none !important;
    }
  // }

  .section-head-of-page {
    .banner {
      object-position: right;
    }

    .banner-mobile {
      height: 100vh;
      object-fit: cover;
      object-position: center;
    }

    .slide-image-mobile {
      width: 100%;
      height: 100vh;
      object-fit: cover;
      object-position: center;
      opacity: 0;
    }

    .hero-text-wrapper {
      max-width: 650px ;
      transform: translateY(-20%);

      .hero-text, .hero-text-transform {
        font-size: 3.438rem; // 55px
      }

      .an-hero-desc {
        * {
          max-width: 100%;
        }
      }

      &.hero-text-wrapper-no-cta {
        transform: translateY(-10%) !important;
      }
    }
  }

  .marquee-text-unrivaled {
    .marquee-container {
      .marquee {
        .text-unrivaled-slider {
          .text-info {
            font-size: 12.5rem; // 200px
          }
        }
      }
    }
  }

  .sc-home-tab-story {
    .nav-tab-wrapper {
      .nav-tabs {
        flex-wrap: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
      }

      .nav-tabs,
      .tab-content {
        left: 0;
        right: 0;
      }
    }
  }

  .sc-home-info-package {
    .wp-title-description {
      * {
        font-size: 2.5rem;
      }
    }
  }

  .my-swiper-package {
    .swiper-slide {
      &.card-package {
        .product-title {
          font-size: 1.75rem; // 28px
        }

        .dedicated-product-title {
          font-size: 2rem; // 32px
        }

        .product-speed {
          font-size: 2.125rem; // 34px
        }

        .dedicated-product-speed {
          font-size: 4.25rem; // 68px
        }

        .product-unit {
          font-size: $text-lg;
        }

        svg {
          height: 32px;
          width: 32px;
        }
      }
    }
  }

  .packet-signature {
    .illustration-globe {
      width: 100%;
    }

    .main-sub-description-wrapper {
      * {
        font-size: $text-base;
        margin-bottom: 0;
      }
    }
  }

  .dedicated-article {
    .wp-dedicated-article {
      .card {
        &.card-featured {
          .meta-wrapper {
            padding: 2rem;
          }
        }
      }
    }
  }

  .xLite-addon-banner {
    height: 450px;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  .page-product-wp-section-xlite {
    .illustration-globe {
      top: 131px;
      width: 100%;
    }

    .img-linear-background {
      top: 450px;
    }
  }

  .wp-page-dedicated {
    .illustration-globe {
      top: 100px;
    }

    .img-linear-background {
      top: 600px;
    }
  }

  .coverage-area-section {
    .coverage-area-bg {
      //svg {
      //  height: 48px;
      //  width: 48px
      //}
    }
  }

  .section-head-coverage-area {
    .text-head-coverage-area {
      font-size: 2.5rem;
    }
  }

  .form-check-coverage-new {
    .text-form-check-coverage {
      font-size: 2.5rem;
    }
  }

  .wp-head-page-promo {
    .wp-promo-slider {
      border-radius: 0.5rem;
      
      .swiper-wrapper {
        .swiper-slide {
          border-radius: 0.5rem;

          .meta-wrapper {
            padding: 2rem;
          }
        }
      }
    }
  }

  .wp-hero-toggle-audio {
    position: absolute;
    left: 3rem;
    bottom: 2rem;
    height: 32px;
    width: 32px;
  }

  .mobile-padding-x-card-36 {
    padding-left: 36px;
    padding-right: 36px;
  }
}

// min-width: 768px
@include media-breakpoint-up(md){
  .swiper-hide-pagination-md {
    .swiper-pagination {display: none;}
  }
}

// max-width: 768px and min-width: 425px
//@include media-breakpoint-down(md) {
@media (max-width: 768px) {
  .section-radius-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .section-head-of-page {
    .banner {
      object-position: 90%;
    }

    .hero-text-wrapper {
      top: 45%;
      transform: none;
      max-width: 380px;

      .hero-text, .hero-text-transform {
        font-size: 2.125rem; // 34px
        //line-height: unset;
        line-height: normal;
      }

      .hero-desc {
        * {
          font-size: $text-base;
          line-height: normal;
          opacity: 80%;
        }
      }
    }

    // .hero-video {
    //   .player-wrapper {
    //     .overlay-video {
    //       background: linear-gradient(90deg,#000 100%,transparent 96.1%);
    //     }
    //   }
    // }
  }

  .marquee-text-unrivaled {
    .marquee-container {
      .marquee {
        .text-unrivaled-slider {
          .text-info {
            font-size: 9.375rem; // 150px
          }
        }
      }
    }
  }

  .sc-home-tab-story {
    .nav-tab-banner {object-position: -700px;}
    .nav-tab-wrapper {
      .tab-content {
        .tab-pane {
          h3 {font-size: 1.75rem;}
        }
      }
    }
  }

  .sc-home-info-package {
    .illustration-globe {
      top: -3.5%;
    }

    .img-linear-background {
      top: 15%;
      height: 228px;
    }

    .wp-title-description {
      * {
        font-size: 1.75rem;
      }
    }
  }

  .my-swiper-package {
    .swiper-slide {
      &.card-package {
        .product-title {
          font-size: 1.625rem; // 26px
        }

        .product-speed {
          font-size: 4.25rem; // 68px
        }

        svg {
          height: 26px;
          width: 26px;
        }
      }

      //padding: 25px 1rem 25px 1rem;
      min-height: 13.75rem;
    }
  }

  .wrap-accordion-faq-arrow-diagonal {
    .faq-list {
      .card {
        .card-header {
          svg {
            height: 26px;
            width: 26px;
          }
        }
      }
    }
  }

  .gx-app-promo {
    .card {
      &.card-mobile-app {
        .mobile-app-wrapper {
          img {
            width: 140px;
          }
        }
      }
    }
  }

  .my-partner {
    .partner-description-wrapper {
      * {
        font-size: 1.75rem;
      }
    }
  }

  .sc-faq-only {
    p {
      font-size: $text-base;
    }

    h2 {
      font-size: 1.75rem; // 28px
      line-height: unset;
    }

    .card {
      &.card-faq {
        .card-header {
          padding: 1.5rem 1rem;

          h5 {
            font-size: $text-base;
          }
        }

        .card-body {
          padding: 0 1rem 1.5rem 1rem;
        }
      }
    }
  }

  //.packet-signature {
  //  .advantages-list {
  //    .advantages-list-border {
  //      //flex-direction: column;
  //
  //      svg {
  //        align-self: flex-start;
  //      }
  //    }
  //  }
  //}

  .coverage-area-section {
    //.text-title {
    //  font-size: $text-lg;
    //}

    //.coverage-area-bg {
    //  svg {
    //    height: 40px;
    //    width: 40px
    //  }
    //}
  }

  .wp-page-dedicated {
    .dedicated-intro-banner {
      height: 400px;
      // object-position: -250px;
    }

    .img-linear-background {
      top: 400px;
    }
  }

  .dedicated-article {
    .point-description {
      font-size: $text-base;
    }
  }

  .product-xlite,
  .packet-xlite,
  .product-signature,
  .packet-signature,
  .customize-service-icon {
    .point-title {
      font-size: $text-xl;
    }
  }


  .wp-page-product-banner-service {
    min-height: auto;
    .icon-link {height: 1rem; width: 1rem;}
  }




  .section-head-coverage-area {
    .illustration-globe,
    .illustration-globe-left {
      width: 100%;
      height: 100%;
    }

    .illustration-globe-left {
      top: 50%;
      transform: translateY(-50%);
      left: -25%;
    }

    .img-linear-background-left {
      top: 50%;
      transform: translateY(-25%);
    }

    .text-head-coverage-area {
      font-size: 2rem;
    }
  }

  .packet-coverage-area {
    .card {
      &.custom-card-packet {
        padding: 2.5rem;

        .text-description {
          font-size: $text-2xl !important;
        }
      }
    }
  }

  .form-check-coverage-new {
    .text-form-check-coverage {
      font-size: 1.75rem;
    }
  }

  .wp-map-config {
    > div:first-child {
      height: 407px;
    }
  }

  .wp-head-page-promo {
    height: 100vh;

    .wp-promo-slider {
      .swiper-pagination {
        bottom: 8% !important;
      }

      .swiper-wrapper {
        .swiper-slide {
          height: 70vh;
          max-height: 500px;
        }
      }
    }
  }

  .wp-category-list {
    .special-promo-title {
      font-size: 1.5rem;
    }

    .category-list {
      flex-wrap: nowrap;
      overflow-x: scroll;
      overflow-y: hidden;
    }
  }

  .wp-head-page-promo-detail {
    .img-wrapper {
      height: 70vh;
      max-height: 260px;
    }
  }

  footer {
    .sitemap {
      transform: none !important;

      h2 {
        font-size: 1.875rem;
        line-height: unset;
      }

      .information-link {margin-bottom: 38px;}
     }
  }

  .section-padding {
    padding-bottom: 3rem;
    padding-top: 3rem;
  }

  .section-padding-top {
    padding-top: 3rem;
  }

  .section-padding-bottom {
    padding-bottom: 3rem;
  }


  // Padding Setup
  .mobile-pt-8-rem {padding-top: 8rem;}
  .mobile-pt-5-rem {padding-top: 5rem;}
  .mobile-pt-4-rem {padding-top: 4rem;}
  .mobile-pt-3-rem {padding-top: 3rem;}


  .mobile-pb-8-rem {padding-bottom: 8rem;}
  .mobile-pb-5-rem {padding-bottom: 5rem;}
  .mobile-pb-4-rem {padding-bottom: 4rem;}
  .mobile-pb-3-rem {padding-bottom: 3rem;}


  // Font Size to Responsive
  .mobile-fs-32, .mobile-fs-32 * {font-size: 2rem;}
  .mobile-fs-28, .mobile-fs-28 * {font-size: 1.75rem;}
  .mobile-fs-24, .mobile-fs-24 * {font-size: 1.5rem;}
  .mobile-fs-20, .mobile-fs-20 * {font-size: 1.25rem;}
  .mobile-fs-16, .mobile-fs-16 * {font-size: 1rem;}
  .mobile-fs-14, .mobile-fs-14 * {font-size: 0.875rem;}
  .mobile-fs-12, .mobile-fs-12 * {font-size: 0.75rem;}

  .slide-card-customize-service {padding: 4rem 3rem 3rem;}

  // Convert Border Radius in Mobile Version
  .b-rad-16 {border-radius: 0.5rem;}
  .b-rad-24 {border-radius: 0.75rem;}

  .mobile-b-rad-8 {border-radius: .5rem;}

  .wp-head-page-general {
    .head-page-general {
      min-height: 200px;
    }
  }


  .wp-blog-wordpress-content {
    h2{font-size: 1.75rem;}
    h3 {font-size: 1.5rem;}
    h4 {font-size: 1.25rem;}
    h5, h6 {font-size: 1.125rem;}

    img {
      border-radius: 4px;
    }
  }

  .blog-detail-header {
    height: 100%;
  }

  .blog-detail-overflow {
    margin-top: -10rem;
  }
}

// max-width: 576px
@include media-breakpoint-down(sm) {
  .navbar {
    .navbar-toggler {
      &.fixed-menu-mobile {
        right: 1.5rem;
      }
    }
  }
  
  .navbar-brand {
    .custom-gx-header-logo {
      left: 1.5rem;
    }
  }

  .wp-hero-toggle-audio {
    left: 1.5rem;
  }

  .mobile-w-25 {width: 25%!important;}
  .mobile-w-50 {width: 50%!important;}
  .mobile-w-75 {width: 75%!important;}
  .mobile-w-100 {width: 100%!important;}

  .section-head-of-page {
    .hero-text-wrapper {
      //transform: translate(0%, -50%);

      //transform: translate(0, -50%);
      top: 60%;
      transform: translate(0, -65%);
      left: 0;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      width: 100%;
      max-width: unset!important;
    }
  }

  .form-check-coverage-new {
    .custom-maps {
      top: -12%;
    }
  }

  .mobile-mt-n-0 {margin-top: 0;}

  .mobile-lh-normal {line-height: normal;}

  .mobile-section-unpadding-top {padding-top: unset;}
  .mobile-section-unpadding-bottom {padding-bottom: unset;}
  .mobile-section-unpadding {padding-top: unset; padding-bottom: unset;}

  .contact-data {
    padding: 1.5rem 1rem;

    .data-thumbnail {
      margin-right: 1rem;
      padding: .5rem;
      img {
        width: 20px;
        height: 20px;
      }
    }

    .data-content {
      justify-content: space-between;
      flex: 1 auto;
    }

    .data-icon {
      padding: 0;

      height: 1.5rem;
      width: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 16px;
        width: 16px;
      }
    }
  }

  .mobile-icon-16 {
    height: 16px;
    width: 16px;
  }
  .mobile-icon-20 {
    height: 20px;
    width: 20px;
  }


  .custom-wrap-maps {
    min-height: 65vh;
    margin-bottom: 3rem;
  }


  // Modal Responsive
  .modal-coverage-area-right-position {
    .modal-dialog {
      margin-right: 1rem;
      margin-left: 1rem;
      max-width: initial;
    }

    .modal-header {
      padding: 1rem;
    }

    .modal-body {
      padding: 0 1rem 1rem;
    }
  }
}

@media screen and (max-width: 375px) {
  .section-padding-bottom {
    .text-break-mobile {
      inline-size: 320px;
      overflow-wrap: break-word;
      hyphens: manual;
      -webkit-hyphens: manual;
    }
  }

  .section-head-of-page {
    .hero-text-wrapper {
      max-width: 350px;

      .hero-text, .hero-text-transform {
        font-size: $text-3xl;
      }
    }
  }

  .my-swiper-package {
    .swiper-slide {
      //padding: 50px 1rem 25px 1rem;
      padding: 25px 1rem 25px 1rem;

      &.card-package {
        .product-speed {
          font-size: 3.75rem; // 60px
        }
      }
    }
  }

  .gx-app-promo {
    .card {
      &.card-mobile-app {
        padding: 76px 1rem 54px 1rem;
      }
    }
  }

  .dedicated-article {
    .wp-dedicated-article {
      .card {
        &.card-featured {
          .meta-wrapper {
            padding: 1.5rem;
          }
        }
      }
    }
  }

  .wp-page-dedicated {
    .img-linear-background {
      top: 300px;
    }
  }

  .g-recaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(0.88);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }

  footer {
    .sitemap {
      padding-bottom: 1rem;

      .bg-rectangle-sitemap {
        width: 300px;
        height: 450px
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .section-padding-bottom {
    .text-break-mobile {
      inline-size: 270px;
    }
  }

  .section-head-of-page {
    .hero-text-wrapper {
      max-width: 280px;

      .hero-text, .hero-text-transform {
        font-size: $text-2xl;
      }
    }
  }

  .my-swiper-package {
    .swiper-slide {
      &.card-package {
        .product-speed {
          font-size: 2.625rem; // 42px
        }

        .product-title,
        span {
          font-size: $text-2xl;
        }
      }
    }
  }

  .my-swiper-article {
    .swiper-slide {
      &.card {
        .card-footer {
          h3 {
            font-size: $text-xl;
          }
        }
      }
    }
  }

  .gx-app-promo {
    .card {
      &.card-mobile-app {
        .mobile-app-wrapper {
          img {
            width: 115px;
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: none;
    transform: translateY(0);
  }
}