// Extra Extra Thin
@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 100;
  src: url('font/NeueHaasDisplayXXThin.ttf') format('truetype'),
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: italic;
  font-weight: 100;
  src: url('font/NeueHaasDisplayXXThinItalic.ttf') format('truetype'),
}

// Extra Thin
@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 200;
  src: url('font/NeueHaasDisplayXThin.ttf') format('truetype'),
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: italic;
  font-weight: 200;
  src: url('font/NeueHaasDisplayXThinItalic.ttf') format('truetype'),
}

// Thin
@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 300;
  src: url('font/NeueHaasDisplayThin.ttf') format('truetype'),
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: italic;
  font-weight: 300;
  src: url('font/NeueHaasDisplayThinItalic.ttf') format('truetype'),
}

// Light
@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 400;
  src: url('font/NeueHaasDisplayLight.ttf') format('truetype'),
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: italic;
  font-weight: 400;
  src: url('font/NeueHaasDisplayLightItalic.ttf') format('truetype'),
}

// Medium
@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 500;
  src: url('font/NeueHaasDisplayRoman.ttf') format('truetype'),
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: italic;
  font-weight: 500;
  src: url('font/NeueHaasDisplayRomanItalic.ttf') format('truetype'),
}

// Semibold
@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 600;
  src: url('font/NeueHaasDisplayMediu.ttf') format('truetype'),
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: italic;
  font-weight: 600;
  src: url('font/NeueHaasDisplayMediumItalic.ttf') format('truetype'),
}

// Bold
@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 700;
  src: url('font/NeueHaasDisplayBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: italic;
  font-weight: 700;
  src: url('font/NeueHaasDisplayBoldItalic.ttf') format('truetype');
}

// Extra Bold
@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 900;
  src: url('font/NeueHaasDisplayBlack.ttf') format('truetype');
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: italic;
  font-weight: 900;
  src: url('font/NeueHaasDisplayBlackItalic.ttf') format('truetype');
}